import React, { Component } from 'react';
import { Table, Input, Button, Space } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import CrearCategoria from './CrearCategoria';
import ModificarCategoria from './ModificarCategoria';
import BajaCategoria from './BajaCategoria';
import DetalleCategoria from './DetalleCategoria';
import CrearAlta from '../Altas/CrearAlta';

export default class ListadoCategorias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: []
        }
    };

    peticionGet = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios.get('Categoria', config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config
                .then(response => {
                    this.setState({
                        data: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    //Esto es el ciclo de vida, en clases utilizamos esto y en funciones se usa useEffect().
    // Pinta en pantalla la consulta
    componentDidMount() {
        this.peticionGet();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idCategoria',
                key: 'idCategoria',
                width: '20%',
                ...this.getColumnSearchProps('idCategoria'),
                sorter: (a, b) => a.idCategoria - b.idCategoria,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Categoria',
                dataIndex: 'descripcion',
                key: 'descripcion',
                width: '30%',
                ...this.getColumnSearchProps('descripcion'),
                sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '40%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarCategoria
                                    filaSeleccionada={fila}
                                    listaCategorias={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaCategoria
                                    filaSeleccionada={fila}
                                    listaCategorias={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleCategoria
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <CrearAlta
                                    filaSeleccionada={fila}
                                    listaCategorias = {this.peticionGet}
                                    datos={this.state.data}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Categorias</h3>
                </div>
                <br />
                <CrearCategoria
                    listaCategorias={this.peticionGet}
                    datos={this.state.data}
                />
                <br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ pageSize: 20 }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idCategoria} />
            </div>
        );
    }
}