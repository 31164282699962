import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaTrabajo extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        trabajo: {
            IdTrabajo: '',
            Nombre: '',
            FechaInicio: '',
            FechaFin: '',
            IdCliente: '',
            IdUsuario: ''
        }
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = () => {
        this.setState({
            trabajo: {
                IdTrabajo: this.props.filaSeleccionada.idTrabajo,
                Nombre: this.props.filaSeleccionada.nombre,
                FechaInicio: this.props.filaSeleccionada.fechaInicio,
                FechaFin: this.props.filaSeleccionada.fechaFin,
                IdCliente: this.props.filaSeleccionada.idCliente,
                IdUsuario: this.props.filaSeleccionada.idUsuario, // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js                  
            }
        });
    }

    // DELETE: api/Usuario/5
    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('Trabajo/' + this.state.trabajo.IdTrabajo, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaTrabajos();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone 
                    twoToneColor="#cf1322"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Trabajo - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar el trabajo <b>{this.state.trabajo && this.state.trabajo.Nombre}</b>
                </Modal>
            </div>
        )
    }
}