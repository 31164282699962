import React, { Component } from 'react'
import 'antd/dist/antd.css'; //npm i antd (instalar)
import '../Estilos/estilos.css';
import { Form, Input, Button, Image } from 'antd'; //npm i antd (instalar)
import LogoOscuro from '../Logos/LogoOscuro.png';
import { UserOutlined, LockOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaLogin } from './Mensajes';
import { expiredToken } from './Autorizacion';
import { Redirect } from 'react-router';

export default class Login extends Component {
    constructor(props){
        super(props);

        this.state = {            
            hayDatosform: false,
            form: {
                usuario: '',
                contrasenia: ''
            },
            token: false
        }
    }    

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            form: {
                ...this.state.form,
                [name]: value
            },
        });
        this.haydatosFormulario()
    }

    haydatosFormulario = async () => {
        if (this.state.form.usuario !== '' && this.state.form.contrasenia !== '') {
            await this.setState({ hayDatosform: true });
        }
        else {
            await this.setState({ hayDatosform: false });
        }
    }

    iniciarSesion = async () => {        
        if (this.state.hayDatosform) {
            await axios.post('Token', this.state.form)
                .then(response => {                    
                    localStorage.setItem('Token', response.data.token); 
                    sessionStorage.setItem('Usuario', this.state.form.usuario)
                    this.setState({
                        token: true
                    });

                }).catch(error => {
                    mostrarAlertaLogin('Nombre o contraseña invalidos');
                });               
        }
        
    }   

    render() { 
        if(!expiredToken()){
            return <Redirect to = '/PaginaPrincipal'/>
        }

        if(this.state.token){  
           return <Redirect to = '/PaginaPrincipal'/>
        }

        return (
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh' }}>
                <div className="centrarLogin">
                    <Image
                        src={LogoOscuro}
                        preview={false} />
                    <p></p>
                    <Form
                        name="normal_login"
                        className="login-form"
                    >
                        <Form.Item>
                            <Input
                                name="usuario"
                                type="text"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="usuario"
                                onChange={this.handleChange}
                                required />
                        </Form.Item>
                        <Form.Item>
                            <Input
                                name="contrasenia"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                onChange={this.handleChange}
                                required
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button" onClick={() => this.iniciarSesion()}>
                                Log in
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        )
    }
}
