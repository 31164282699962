import React, { Component } from 'react'
import { Redirect } from 'react-router';
import { BrowserRouter, Link, Route } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { UserOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import { Layout, Breadcrumb, Image, Switch, Descriptions, Button } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import Logo from '../Logos/Logo.png';
import LogoOscuro from '../Logos/LogoOscuro.png';
import LogoMini from '../Logos/LogoMini1.png';
import LogoMiniOscuro from '../Logos/LogoMini1Oscuro.png';
import WTC from '../Logos/WTC.png';
import ListadoRoles from '../Entidades/Roles/ListadoRoles';
import ListadoUsuarios from '../Entidades/Usuarios/ListadoUsuarios';
import ListadoEstadoFactura from '../Entidades/EstadoFactura/ListadoEstadoFactura';
import ListadoCondicionPago from '../Entidades/CondicionPago/ListadoCondicionPago';
import ListadoPortfolios from '../Entidades/Portfolios/ListadoPortfolios';
import ListadoFotografias from '../Entidades/Fotografias/ListadoFotografias';
import ListadoClientes from '../Entidades/Clientes/ListadoClientes';
import ListadoTrabajos from '../Entidades/Trabajo/ListadoTrabajos';
import ListadoFacturas from '../Entidades/Facturas/ListadoFacturas';
import ListadoPagos from '../Entidades/Pagos/ListadoPagos';
import ListadoCategorias from '../Entidades/Categorias/ListadoCategorias';
import ListadoAltas from '../Entidades/Altas/ListadoAltas';
import Ayuda from './Ayuda';
import MenuAdministrador from '../MenuXRol/MenuAdministrador';
import MenuUsuario from '../MenuXRol/MenuUsuario';
import MenuEmpleado from '../MenuXRol/MenuEmpleado';
import '../Estilos/estilos.css';
import { mostrarErrorCatch } from '../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from './Autorizacion';
import Logout from './Logout';
import InformacionUsuario from './Graficas/InformacionUsuario';
import InformacionManager from './Graficas/InformacionManager';
import InformacionEmpleado from './Graficas/InformacionEmpleado';
import ListadoFacturasVencidas from '../Entidades/Facturas/ListadoFacturasVencidas';

const { Header, Content, Footer, Sider } = Layout;

export default class PaginaPrincipal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: 'dark',
            collapsed: false,
            data: [],
            tipoRol: ''
        }
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    changeTheme = value => {
        this.setState({
            theme: value ? 'dark' : 'light',
        });
    };

    obtenerUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        data: response.data.data,
                        tipoRol: response.data.data.unObjRol.tipoRol
                    }); // Cargo en data los resultados de la consulta 
                    sessionStorage.setItem('IdUsuario', response.data.data.idUsuario)
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.obtenerUsuario();
    }

    render() {
        if (expiredToken()) {
            return <Redirect to='/' />
        }

        if (localStorage.getItem('Token') === null) {
            return <Redirect to='/' />
        }

        const { collapsed } = this.state;
        const { theme } = this.state;
        let div;
        let descripcion;
        let menuXrol; // Para saber que usuario esta logeado segun el rol        

        //#region Editar logo, color, y descripcion al minimizar el menu

        //Cambia el tamaño del logo al minimizar el menu, el segundo if camba el color del menu y el del titulo de la descripcion
        if (!collapsed) {
            if (theme === 'dark') {
                div = <div className="logo">
                    <Image
                        width={150}
                        src={Logo}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "white",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PRINCIPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
            else {
                div = <div className="logo">
                    <Image
                        width={150}
                        src={LogoOscuro}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "black",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PRINCIPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
        }
        else {
            if (theme === 'dark') {
                div = <div className="logoMini">
                    <Image
                        width={50}
                        src={LogoMini}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "white",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
            else {
                div = <div className="logoMini">
                    <Image
                        width={50}
                        src={LogoMiniOscuro}
                        preview={false}
                    />
                </div>
                descripcion = <div className="titulo">
                    <Descriptions>
                        <Descriptions.Item
                            contentStyle={{
                                color: "black",
                                fontSize: 12,
                            }}
                            theme={this.state.theme}>
                            MENU PPAL
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            }
        }

        if (this.state.tipoRol === 'Administrador') {
            menuXrol = <MenuAdministrador theme={this.state.theme} />
        } else {
            if (this.state.tipoRol === 'Usuario') {
                menuXrol = <MenuUsuario theme={this.state.theme} />
            } else {
                if (this.state.tipoRol === 'Empleado') {
                    menuXrol = <MenuEmpleado theme={this.state.theme} />
                }
            }
        }

        //#endregion

        return (
            <BrowserRouter>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sider theme={this.state.theme} collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                        <Link to="/PaginaPrincipal">
                            {div}
                        </Link>
                        {/* Muestra el titulo y el logo segun color al minimizar el menu (hay un if mas arriba) */}
                        {descripcion}
                        {/* Muestra el menu de acuerdo al usuario logeado*/}
                        {menuXrol}

                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }} >

                            <b className='usuarioSesion'><UserOutlined /></b> Usuario: <strong> {this.state.data.nombre} </strong>

                            <Logout />
                        </Header>
                        <Content style={{ margin: '0 16px' }}>
                            <Breadcrumb style={{ margin: '16px 0' }}>
                                <Breadcrumb.Item>
                                    <Link to="/Inicio">
                                        Inicio
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Ayuda />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Switch
                                        checked={this.state.theme === 'dark'}
                                        onChange={this.changeTheme}
                                        checkedChildren="Oscuro"
                                        unCheckedChildren="Claro"
                                    />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>

                                </Breadcrumb.Item>
                            </Breadcrumb>
                            <div className="site-layout-background" style={{ padding: 24, minHeight: '100%' }}>
                                <Route exact path="/ListadoRoles" component={ListadoRoles} key="1" />
                                <Route exact path="/ListadoUsuarios" component={ListadoUsuarios} key="2" />
                                <Route exact path="/ListadoEstadoFacturas" component={ListadoEstadoFactura} key="3" />
                                <Route exact path="/ListadoCondicionPagos" component={ListadoCondicionPago} key="4" />
                                <Route exact path="/ListadoPortfolios" component={ListadoPortfolios} key="5" />
                                <Route exact path="/ListadoFotografias" component={ListadoFotografias} key="6" />
                                <Route exact path="/ListadoClientes" component={ListadoClientes} key="7" />
                                <Route exact path="/ListadoTrabajos" component={ListadoTrabajos} key="8" />
                                <Route exact path="/ListadoFacturas" component={ListadoFacturas} key="9" />
                                <Route exact path="/ListadoFacturasVencidas" component={ListadoFacturasVencidas} key="10" />
                                <Route exact path="/ListadoPagos" component={ListadoPagos} key="11" />
                                <Route exact path="/ListadoCategorias" component={ListadoCategorias} key="12" />
                                <Route exact path="/ListadoAltas" component={ListadoAltas} key="13" />
                                <Route exact path="/Ayuda" component={Ayuda} key="14" />
                                {(() => { // Encaso de queres colocar mas de 1 renglon dentro de 1 if usar asi (this.state.tipoRol === 'Administrador' ? en este caso solo permite 1 renglon)
                                    if (this.state.tipoRol === 'Administrador') {
                                        return (
                                            <div>
                                                <Route exact path="/Inicio" component={InformacionManager} key="15" />

                                                <Route exact path="/PaginaPrincipal" component={InformacionManager} key="16" />
                                            </div>)
                                    } else if (this.state.tipoRol === 'Usuario') {
                                        return (
                                            <div>
                                                <Route exact path="/Inicio" component={InformacionUsuario} key="15" />

                                                <Route exact path="/PaginaPrincipal" component={InformacionUsuario} key="16" />
                                            </div>)
                                    }else if (this.state.tipoRol === 'Empleado') {
                                        return (
                                            <div>
                                                <Route exact path="/Inicio" component={InformacionEmpleado} key="15" />

                                                <Route exact path="/PaginaPrincipal" component={InformacionEmpleado} key="16" />
                                            </div>)
                                    }
                                })()}
                            </div>
                        </Content>

                        <Footer style={{ textAlign: 'left' }} >
                            <strong >Copyright © 2021-2022
                                <Image
                                    src={WTC}
                                    width={120}
                                    preview={false} />
                            </strong>
                            All rights reserved.
                            <div className='footerRight' >
                                <b>Version</b> 1.1.0
                            </div>
                        </Footer>
                    </Layout>
                </Layout>
            </BrowserRouter>
        )
    }
}