import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter1, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class CrearFotografia extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoArchivo: 0,
        error: false,
        detalleError: '',
        validacionS: false,
        archivos: null,
        archivo: {
            IdArchivo: '',
            idPortfolio: '',
        },
        portfolio: [],
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    obtenerPortfolioSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('Portfolio', config)
                .then(response => {
                    this.setState({ portfolio: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo   
        if (this.state.i >= 0) {
            await this.setState({ ultimoArchivo: arreglo[this.state.i].idArchivo + 1 }); // obtengo el ultimo IdUsuario y le sumo para colocarlo en el value del Modal
        }
        else {
            this.setState({ ultimoArchivo: 1 })
        }
        await this.setState({
            archivo: {
                IdArchivo: this.state.ultimoArchivo,
                idPortfolio: '',
            },
            archivos: null
        });
        this.obtenerPortfolioSelect();
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.archivo.idPortfolio === '0' || this.state.archivo.idPortfolio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleFileChange = e => {
        this.setState({
            [e.target.name]: e.target.files,
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionS: this.validacionSelect() });
            if (this.state.validacionS === false) { // Si no selecciona el portfolio muestro el mensaje de error y no entro al post                             
                delete this.state.archivo.IdArchivo; //Borro el Id ya que la bd lo creo automaticamente 
                const formData = new FormData();
                if (this.state.archivos != null) {
                    for (let index = 0; index < this.state.archivos.length; index++) {
                        formData.append('archivos', this.state.archivos[index]);
                    }
                }
                //console.log('arch',formData.get('archivos')) esta linea es para ver lo que hay en el append, ya que si coloco solo formData no me muestra nada
                await axios.post('Archivo?idPortfolio=' + this.state.archivo.idPortfolio, formData, config)
                    .then(response => {
                        this.props.listaArchivos(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                        this.onClose(); // Cierra el modal luego del ingreso  
                        mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                    }).catch(error => {
                        if (error.response) {
                            mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                            this.setState({
                                error: true,
                                detalleError: error.response.data.errors[0].detail
                            }); // cambio el estado de error a true para que se muestre en el pie de drawer
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false, detalleError:'' });
                    }}
                >
                    <PlusOutlined /> Nueva Fotografia
                </Button>

                <Drawer
                    title="Fotografia - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="idArchivo"
                                type="text"
                                id="idArchivo"
                                size="small"
                                value={this.state.archivo && this.state.archivo.IdArchivo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Archivo">
                            <Input
                                style={{ border: 0 }}
                                name="archivos"
                                multiple
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"
                                onChange={this.handleFileChange}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Portfolio"
                            required
                        >
                            <Select
                                name="idPortfolio"
                                id="idPortfolio"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        archivo: {
                                            ...this.state.archivo,
                                            idPortfolio: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.archivo && this.state.archivo.idPortfolio) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar portfolio </label></Option>
                                {this.state.portfolio.map(elemento => (
                                    <Option key={elemento.idPortfolio} value={elemento.idPortfolio}>{elemento.nombreLugar}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un portfolio')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}