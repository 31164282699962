import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker } from 'antd'; //npm i antd (instalar)
import { EuroCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaExito, mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1 } from '../../Paginas/Mensajes';
import { esNumeroPunto } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class CrearPago extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        error: false,
        detalleError: '',
        validacion: false,
        validacionF: false,
        pago: {
            idPago: '',
            fecha: '',
            monto: '',
            idFactura: '',
        },
        Factura: '',
        Cliente: '',
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionF: false, // desaparece el cartel de validacion al cancelar (Fecha Inicio)  
        });
    };

    cargarDatos = async () => {
        await this.setState({
            pago: {
                idPago: '',
                fecha: '',
                monto: '',
                idFactura: this.props.filaSeleccionada.idFactura,
            },
            Factura: this.props.filaSeleccionada.numero, // Guardo el numero de factura que viene del listado facturas
            Cliente: this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre // Guardo el nombre del cliente que viene de listado facturas
        });
    }

    validacionForm = async () => {
        if (!esNumeroPunto(this.state.pago.monto)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    validacionFecha = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.pago.fecha === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFecha = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                pago: {
                    ...this.state.pago,
                    fecha: ''
                },
                validacionF: true //al colocar esto se muestra el msj de La fecha no puede ser vacia
            })
        } else {
            await this.setState({
                pago: {
                    ...this.state.pago,
                    fecha: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false //al colocar esto se borra el msj de La fecha no puede ser vacia
            })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            pago: {
                ...this.state.pago,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFecha() }) // Devuleve true si no ingrese fecha, false en caso contrario 
            if (this.state.validacionF === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post                 
                delete this.state.pago.idPago; //Borro el Id ya que la bd lo creo automaticamente                                  
                await axios.post('Pago', this.state.pago, config) // Inserta en la Base de datos el nuevo registro
                    .then(response => {
                        this.props.listaFacturas(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                        this.onClose(); // Cierra el modal luego del ingreso  
                        mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                    }).catch(error => {
                        if (error.response) {
                            console.log('Factura', error.response);
                            if (error.response.data.errors.fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                mostrarAlertaError(error.response.data.errors.Fecha[0])
                                this.setState({
                                    error: true,
                                    detalleError: error.response.data.errors.Fecha[0]
                                })
                            }
                            else {
                                mostrarAlertaError(error.response.data.errors[0].detail);
                            }
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })

            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                {this.props.filaSeleccionada.idEstado !== 3 ?
                    <EuroCircleTwoTone
                        twoToneColor="#1890ff"
                        style={{ fontSize: '15px' }}
                        onClick={() => {
                            this.showDrawer();
                            this.setState({ tipoModal: 'insertar' });
                            this.setState({ error: false });
                        }} />
                    :
                    <EuroCircleTwoTone
                        twoToneColor="#bfbfbf"
                        style={{ fontSize: '15px' }}
                    />
                }

                <Drawer
                    title="Pago - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero"
                            hidden>
                            <Input
                                disabled
                                hidden
                                name="idPago"
                                type="text"
                                id="idPago"
                                size="small"
                                value={this.state.pago && this.state.pago.idPago}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nº Factura"
                        >
                            <Input
                                name="numero"
                                type="text"
                                disabled
                                id="numero"
                                maxLength={10}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state && this.state.Factura}
                                required
                            >
                            </Input>                            
                        </Form.Item>
                        <Form.Item
                            label="Cliente"
                        >
                            <Input
                                name="Cliente"
                                type="text"
                                disabled
                                id="Cliente"
                                minLength={5}
                                maxLength={70}
                                size="small"
                                value={this.state && this.state.Cliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={this.state.pago.fecha !== "" ? moment(this.state.pago.fecha) : null}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFecha} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Monto"
                            required>
                            <Input
                                name="monto"
                                type="text"
                                id="monto"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.pago && this.state.pago.monto}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El monto no puede contener letras')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}