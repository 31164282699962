import React, { Component } from 'react';
import Chart from 'chart.js/auto'
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { Bar } from 'react-chartjs-2'; //npm install --save react-chartjs-2 chart.js (instalar)
import axios from 'axios';   //npm install axios (instalar)

export default class FacturadoMensual extends Component {

    constructor(props) {
        super(props);

        this.state = {
            total: [],
            totalSinIva: [],
            totalConIva: [],
            fecha: []
        }
    };

    peticion = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        await axios.get('Factura/PorFecha', config)
            .then(response => {
                let facturas = response.data.data
                facturas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxTotal = [], auxFecha = [];
                respuesta.map(elemento => ((
                    auxTotal.push(elemento.total),
                    auxFecha.push(elemento.fecha)
                )));
                this.setState({
                    total: auxTotal,
                    fecha: auxFecha
                }); // Cargo en data los resultados de la consulta               
            })
    }

    peticionSinIva = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        await axios.get('Factura/PorFechaSinIva', config)
            .then(response => {
                let facturas = response.data.data
                facturas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxTotal = [], auxFecha = [];
                respuesta.map(elemento => ((
                    auxTotal.push(elemento.baseImponible),
                    auxFecha.push(elemento.fecha)
                )));
                this.setState({
                    totalSinIva: auxTotal,
                    fecha: auxFecha
                }); // Cargo en data los resultados de la consulta               
            })
    }

    peticionSoloIva = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        await axios.get('Factura/PorFechaSoloIva', config)
            .then(response => {
                let facturas = response.data.data
                console.log('f', facturas)
                facturas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fecha))
                    return el.fecha = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxTotal = [], auxFecha = [];
                respuesta.map(elemento => ((
                    auxTotal.push(elemento.iva),
                    auxFecha.push(elemento.fecha)
                )));
                this.setState({
                    totalSoloIva: auxTotal,
                    fecha: auxFecha
                }); // Cargo en data los resultados de la consulta               
            })
    }

    componentDidMount() {
        this.peticion();
        this.peticionSinIva();
        this.peticionSoloIva();
    }

    render() {
        const data = {

            labels: this.state.fecha,/* datos de la X */
            datasets: [{
                label: 'Con Impuestos',
                backgroundColor: '#08979c',
                borderColor: '#006d75',
                fill: false,
                lineTension: 0,
                pointRadius: 5,
                pointStyle: 'rectRot',
                borderWidth: 1,
                hoverBackgroundColor: '#13c2c2',
                hoverBorderColor: 'white',
                scaleShowGridlLines: false,
                titleDisplay: true,
                data: this.state.total /* datos de la y */
            },
            {
                label: 'Base Imponible',
                backgroundColor: '#096dd9',
                borderColor: '#0050b3',
                fill: false,
                lineTension: 0,
                pointRadius: 5,
                pointStyle: 'rectRot',
                borderWidth: 1,
                hoverBackgroundColor: '#1890ff',
                hoverBorderColor: 'white',
                scaleShowGridlLines: false,
                titleDisplay: true,
                data: this.state.totalSinIva /* datos de la y */
            },
            {
                label: 'Iva',
                backgroundColor: '#7cb305',
                borderColor: '#5b8c00',
                fill: false,
                lineTension: 0,
                pointRadius: 5,
                pointStyle: 'rectRot',
                borderWidth: 1,
                hoverBackgroundColor: '#a0d911',
                hoverBorderColor: 'white',
                scaleShowGridlLines: false,
                titleDisplay: true,
                data: this.state.totalSoloIva /* datos de la y */
            }]
        }

        const options = {
            animation: true,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    onHover: 'handleHover',
                    onLeave: 'handleLeave',
                    position: 'top',
                    style: { marginbottom: 20 },
                    labels: {
                        usePointStyle: true,
                        pointStyle: 'circle',
                        textAlign: 'right',
                        padding: 10,

                    },
                },
            },
            scales: {
                y: {
                    display: true,
                    beginAtZero: true,

                    title: {
                        display: true,
                        text: 'Total €',
                        padding: { top: 0, left: 0, right: 0, bottom: 30 }
                    },
                    grid: {
                        display: false
                    }
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Mes',
                        padding: { top: 20, left: 0, right: 0, bottom: 0 }
                    },
                    grid: {
                        display: false
                    },
                }
            }
        }

        return (
            <div>
                <div style={{ width: '500px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                    <h2 style={{ textAlign: 'center', paddingTop: '10px' }}>Facturas</h2>
                    <Bar data={data} options={options} />
                </div>
            </div>

        )
    }
}