import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom' //npm install react-router-dom (instalar)
import PaginaPrincipal from './PaginaPrincipal';
import Login from './Login';

function Rutas() {
    return ( //estas son las rutas para el inicio de la aplicacion, se importan en index.js, agrego el tercer Route para que cuando presione F5 en la web publicada no me deje la pagina en blanco
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} /> 
                <Route exact path ="/PaginaPrincipal" component={PaginaPrincipal} />
                <Route component={PaginaPrincipal} /> 
            </Switch>
        </BrowserRouter>
    )
}

export default Rutas;