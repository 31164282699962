import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

export default class DetalleRol extends Component {   

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Rol - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idRol} </Descriptions.Item>
                    <Descriptions.Item label="Descripción" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.tipoRol}</Descriptions.Item>

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {        

        return (
            <div>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />  
            </div>
        )
    }
}