import { Component } from 'react';
import { Redirect } from 'react-router';
import { LogoutOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { Button } from 'antd'; //npm i antd (instalar)

export default class Logout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: true
        }
    }

    cerrarSesion = async () => {
        localStorage.clear();
        sessionStorage.clear();
        this.setState(
            {
                token: false
            }
        );

    }


    render() {
        if (!this.state.token) {
            return window.location.href = ('/');
        }

        return (
            <Button type='link' className="botonCerrarSesion" icon={<LogoutOutlined />} onClick={() => this.cerrarSesion()}> Finalizar </Button>
        )

    }
}