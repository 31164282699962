import React, { Component } from 'react';
import { Table, Input, Form, Button, Space, Checkbox } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import get from "lodash.get"; // npm i --save lodash.get
import isequal from "lodash.isequal"; //npm i --save lodash.isequal
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import ModificarTrabajo from './ModificarTrabajo';
import BajaTrabajo from './BajaTrabajo';
import DetalleTrabajo from './DetalleTrabajo';
import CrearFactura from '../Facturas/CrearFactura';
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import { esNombre } from '../../Paginas/Validaciones';
import { mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';

export default class ListadoTrabajos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [], // Aca se guardaran todos los resultados de la consulta que vienen desde la api en peticion get
            paginado: [], // Aca se guardara todos los resultados de la paginacion que vienen desde la api en la peticion get
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
            cliente: '', // usado para realizar la busqueda por cliente
            validacion: false
        }
    };

    validacionForm = async () => {
        if (esNombre(this.state.cliente)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    handleChangeSearch = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            [name]: value
        });
        this.validacionForm();
        if (!this.state.validacion && this.state.pagina === 0) { // Debo validar ademas de que ingrese valores correctos que se encuentre en la pagina 0 porque si estoy en la pagina 2 por ej y escribo un nombre no lo va a encontrar            
            this.peticionGet();
        }
        else {
            this.setState({ //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
                pagina: 0
            })
            this.peticionGet();
        }
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.cliente !== '') {
            return axios.get('Trabajo?nombreCliente=' + this.state.cliente + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        } else {
            return axios.get('Trabajo?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = () => {
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let trabajos = response.data.data
                    trabajos.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let dateInicio = moment(new Date(el.fechaInicio));
                        let dateFin = moment(new Date(el.fechaFin))
                        return el.fechaInicio = dateInicio.format("DD-MM-YYYY"), el.fechaFin = dateFin.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                                                                                                                      
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        } 
    }

    componentDidMount() {
        this.peticionGet();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idTrabajo',
                key: 'idTrabajo',
                width: '7%',
                ...this.getColumnSearchProps('idTrabajo'),
                sorter: (a, b) => a.idTrabajo - b.idTrabajo,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjCliente', 'nombre'],
                key: 'nombre',
                width: '24%',
                ...this.getColumnSearchProps(['unObjCliente', 'nombre']),
                sorter: (a, b) => a.unObjCliente.nombre.localeCompare(b.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Descripcion',
                dataIndex: 'nombre',
                key: 'nombre',
                width: '30%',
                ...this.getColumnSearchProps('nombre'),
                sorter: (a, b) => a.nombre.localeCompare(b.nombre),
                sortDirections: ['descend', 'ascend'],
            },            
            {
                title: 'Fecha Inicio',
                dataIndex: 'fechaInicio',
                key: 'fechaInicio',
                width: '14%',
                ...this.getColumnSearchProps('fechaInicio'),
                sorter: (a, b) => a.fechaInicio.localeCompare(b.fechaInicio),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha Fin',
                dataIndex: 'fechaFin',
                key: 'fechaFin',
                width: '12%',
                ...this.getColumnSearchProps('fechaFin'),
                sorter: (a, b) => a.fechaFin.localeCompare(b.fechaFin),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Facturado',
                dataIndex: 'facturado',
                key: 'facturado',
                render: (value) => (
                    <Checkbox
                      checked={value} 
                      disabled                     
                    />
                  ),
                width: '10%',                
                sorter: (a, b) => a.idTrabajo - b.idTrabajo,
                sortDirections: ['descend', 'ascend'],                              
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarTrabajo
                                    filaSeleccionada={fila}
                                    listaTrabajos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaTrabajo
                                    filaSeleccionada={fila}
                                    listaTrabajos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleTrabajo
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <CrearFactura
                                    filaSeleccionada={fila}
                                    listaTrabajos = {this.peticionGet}
                                    datos={this.state.data}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Trabajos</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>

                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <Input.Search
                                name="cliente"
                                type="text"
                                id="cliente"
                                size="small"
                                maxLength={70}
                                placeholder="Buscar Trabajo X Cliente"
                                onChange={this.handleChangeSearch}
                                value={this.state.cliente}
                            >
                            </Input.Search>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El cliente solo debe contener letras')}
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}                   
                    columns={columns.filter(col => col.dataIndex !== 'idTrabajo' /*&& col.dataIndex !== 'numero'*/)} //Ocluto 1 o varias columnas
                    dataSource={this.state.data}
                    rowKey={row => row.idTrabajo} />
            </div>
        );
    }
}