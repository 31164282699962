import React, { Component } from 'react';
import { Table, Input, Form, Button, Space } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import { esNombre } from '../../Paginas/Validaciones';
import CrearPortfolio from './CrearPortfolio';
import ModificarPortfolio from './ModificarPortfolio';
import BajaPortfolio from './BajaPortfolio';
import DetallePortfolio from './DetallePortfolio';

export default class ListadoPortfolios extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [],
            paginado: [],
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
            nombreLugar: '',
            validacion: false
        }
    };

    validacionForm = async () => {
        if (esNombre(this.state.nombreLugar)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    handleChangeSearch = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            [name]: value
        });
        this.validacionForm();
        if (!this.state.validacion && this.state.pagina === 0) { // Debo validar ademas de que ingrese valores correctos que se encuentre en la pagina 0 porque si estoy en la pagina 2 por ej y escribo un nombre no lo va a encontrar            
            this.peticionGet();
        }
        else {
            this.setState({ //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
                pagina: 0
            })
            this.peticionGet();
        }
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.nombreLugar !== '') {
            return axios.get('Portfolio?nombreLugar=' + this.state.nombreLugar + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        } else {
            return axios.get('Portfolio?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = () => {
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let portfolios = response.data.data
                    portfolios.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let date = moment(new Date(el.fecha));
                        return el.fecha = date.format("DD-MM-YYYY")
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                                                                                                      
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    //Esto es el ciclo de vida, en clases utilizamos esto y en funciones se usa useEffect().
    // Pinta en pantalla la consulta
    componentDidMount() {
        this.peticionGet();
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idPortfolio',
                key: 'idPortfolio',
                width: '10%',
                ...this.getColumnSearchProps('idPortfolio'),
                sorter: (a, b) => a.idPortfolio - b.idPortfolio,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Lugar',
                dataIndex: 'nombreLugar',
                key: 'nombreLugar',
                width: '30%',
                ...this.getColumnSearchProps('nombreLugar'),
                sorter: (a, b) => a.nombreLugar.localeCompare(b.nombreLugar),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '15%',
                ...this.getColumnSearchProps('fecha'),
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '45%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarPortfolio
                                    filaSeleccionada={fila}
                                    listaPortfolios={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaPortfolio
                                    filaSeleccionada={fila}
                                    listaPortfolios={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetallePortfolio
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Portfolios</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <CrearPortfolio
                        listaPortfolios={this.peticionGet}
                        datos={this.state.data} />
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <Input.Search
                                name="nombreLugar"
                                type="text"
                                id="nombreLugar"
                                size="small"
                                maxLength={70}
                                placeholder="Buscar Lugar"
                                onChange={this.handleChangeSearch}
                                value={this.state.nombreLugar}
                            >
                            </Input.Search>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El nombre solo debe contener letras')}
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idPortfolio} />
            </div>
        );
    }
}