import React, { Component } from 'react';
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import axios from 'axios';   //npm install axios (instalar)
import { Row, Col, Divider } from 'antd';
import {
    UsergroupDeleteOutlined,
    RightCircleFilled,
    SnippetsOutlined,
    CalendarOutlined,
    EuroOutlined
} from '@ant-design/icons'; //npm i antd (instalar)
import FacturadoMensual from './FacturadoMensual';
import PagadoMensual from './PagadoMensual';
import { Link } from 'react-router-dom';
import DiasPorAnio from './DiasPorAnio';
import CobradoPorAnio from './CobradoPorAnio';

const style1 = { background: '#08979c', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style2 = { background: '#389e0d', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style3 = { background: '#faad14', padding: '15px 20px', color: 'black', width: '250px', marginLeft: '20px' };
const style4 = { background: '#cf1322', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style6 = { background: '#096dd9', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style7 = { background: '#722ed1', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style8 = { background: '#f0f0f0', padding: '15px 20px', color: 'black', width: '250px', marginLeft: '20px' };
const style9 = { background: '#096dd9', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style10 = { background: '#434343', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style11 = { background: '#eb2f96', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style5 = { padding: '15px 20px' };


export default class InformacionUsuario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataNumClientes: [],
            dataNumTrabajos: [],
            dataTotalfacturado: [],
            dataTotalfacturadoSinIva: [],
            dataTotalPagado: [],
            dataTotalDias: [],
            dataTotalCobradoPorUsuario: [],
            dataTotalCobradoSinImpuestosPorUsuario: [],
            dataTotalImporteAltasSinImpuestosUsuario2: [],
            dataAltasPendientesPorUsuario: [],
            idUsuario: ''
        }
    };

    peticionNumeroClientes = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Cliente/Cantidad', config)
                .then(response => {
                    this.setState({
                        dataNumClientes: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionNumeroTrabajos = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Trabajo/Cantidad', config)
                .then(response => {
                    this.setState({
                        dataNumTrabajos: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalFacturado = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Factura/Total', config)
                .then(response => {
                    this.setState({
                        dataTotalfacturado: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalFacturadoSinIva = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Factura/TotalSinIva', config)
                .then(response => {
                    this.setState({
                        dataTotalfacturadoSinIva: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalPagado = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Pago/Total', config)
                .then(response => {
                    this.setState({
                        dataTotalPagado: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalDiasPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalDias?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalDias: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalCobradoPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalCobrado?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalCobradoPorUsuario: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalCobradoSinImpuestos?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalCobradoSinImpuestosPorUsuario: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalImporteAltasSinImpuestos?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalImporteAltasSinImpuestosUsuario2: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionAltasPendientesPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/CantidadAltas?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataAltasPendientesPorUsuario: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionNumeroClientes();
        this.peticionNumeroTrabajos();
        this.peticionTotalFacturado();
        this.peticionTotalFacturadoSinIva();
        this.peticionTotalPagado();
        this.peticionTotalDiasPorUsuario();
        this.peticionTotalCobradoPorUsuario();
        this.peticionAltasPendientesPorUsuario();
    }

    render() {
        return (
            <div>
                <Divider orientation="left">Resumen de Ventas</Divider>
                <Row >
                    <Col >
                        <div style={style1}>
                            <UsergroupDeleteOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataNumClientes}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Clientes </label>
                            <p></p>
                            <div style={{ background: '#006d75', textAlign: 'center' }}>
                                <Link to={"/ListadoClientes"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col >
                        <div style={style2}>
                            <SnippetsOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataNumTrabajos}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Trabajos Pendientes </label>
                            <p></p>
                            <div style={{ background: '#237804', textAlign: 'center' }}>
                                <Link to={"/ListadoTrabajos"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                </Row>
                <Divider orientation="left">Finanzas</Divider>
                <Row >
                    <Col >
                        <div style={style3}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalfacturado}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total facturado </label>
                            <p></p>
                            <div style={{ background: '#d4b106', textAlign: 'center' }}>
                                <Link to={"/ListadoFacturas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style9}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalfacturadoSinIva}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total base imponible </label>
                            <p></p>
                            <div style={{ background: '#0050b3', textAlign: 'center' }}>
                                <Link to={"/ListadoFacturas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style4}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalPagado}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total cobrado </label>
                            <p></p>
                            <div style={{ background: '#a8071a', textAlign: 'center' }}>
                                <Link to={"/ListadoPagos"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col >
                        <div style={style8}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#8c8c8c' }} />
                            <strong style={{ fontSize: '20px' }}>{Number(this.state.dataTotalfacturado) + Number(this.state.dataTotalImporteAltasSinImpuestosUsuario2)}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total Ingresos</label>
                            <p></p>
                            <div style={{ background: '#bfbfbf', textAlign: 'center' }}>
                                Total facturado y altas
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col >
                        <div style={style10}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#fffff' }} />
                            <strong style={{ fontSize: '20px' }}>{Number(this.state.dataTotalPagado) + Number(this.state.dataTotalCobradoSinImpuestosPorUsuario)}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Ingresos Bancarios </label>
                            <p></p>
                            <div style={{ background: '#262626', textAlign: 'center' }}>
                                Facturas y altas cobradas
                            </div>
                        </div>
                        <br></br>
                    </Col>
                </Row>
                <br></br>
                <Divider orientation="left">Graficos</Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <div style={style5}>
                            <FacturadoMensual />
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style5}>
                            <PagadoMensual />
                        </div>
                        <br></br>
                    </Col>
                </Row >
                <br /><br />
                <Divider orientation="left">Resumen de Altas</Divider>
                <Row>
                    <Col>
                        <div style={style2}>
                            <SnippetsOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataAltasPendientesPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Trabajos Pendientes</label>
                            <p></p>
                            <div style={{ background: '#237804', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style6}>
                            <CalendarOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataTotalDias}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Dias Trabajados </label>
                            <p></p>
                            <div style={{ background: '#0050b3', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style7}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#fffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalCobradoPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total cobrado </label>
                            <p></p>
                            <div style={{ background: '#531dab', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style11}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalCobradoSinImpuestosPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '17px' }}>Total cobrado sin Imp </label>
                            <p></p>
                            <div style={{ background: '#9e1068', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                </Row>
                <br></br>
                <Divider orientation="left">Graficos</Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <div style={style5}>
                            <DiasPorAnio />
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style5}>
                            <CobradoPorAnio />
                        </div>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
            </div >
        )
    }
}