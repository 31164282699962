import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, Image } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaModificar, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class ModificarFotografia extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoArchivo: 0,
        error: false,
        validacionS: false,
        archivos: null,
        archivo: {
            IdArchivo: '',
            Ubicacion: '',
            idPortfolio: ''
        },
        portfolio: [],
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerPortfolioSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)
        });
    };

    cargarDatos = async (e) => {
        await this.setState({
            archivo: {
                IdArchivo: this.props.filaSeleccionada.idArchivo,
                Ubicacion: this.props.filaSeleccionada.ubicacion,
                idPortfolio: this.props.filaSeleccionada.idPortfolio                
            },
            archivos: null,            
        });
    }

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    obtenerPortfolioSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('Portfolio', config)
                .then(response => {
                    this.setState({ portfolio: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.archivo.idPortfolio === '0' || this.state.archivo.idPortfolio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleFileChange = e => {
        this.setState({
            [e.target.name]: e.target.files,
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionS: this.validacionSelect() });
            if (this.state.validacionS === false) { // Si no selecciona el portfolio muestro el mensaje de error y no entro al post
                const formData = new FormData();
                if (this.state.archivos != null) {

                    for (let index = 0; index < this.state.archivos.length; index++) {
                        formData.append('archivo', this.state.archivos[index]);
                    }
                    console.log('arch', formData.get('archivo')) //esta linea es para ver lo que hay en el append, ya que si coloco solo formData no me muestra nada
                }
                await axios.put('Archivo?idArchivo=' + this.state.archivo.IdArchivo + '&idPortfolio=' + this.state.archivo.idPortfolio, formData, config)
                    .then(response => {
                        this.props.listaArchivos(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                        this.onClose(); // Cierra el modal luego del ingreso  
                        mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js                       
                    }).catch(error => {
                        if (error.response) {
                            mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                            this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                        }
                    }).catch(errorServer => {
                        errorServer = mostrarErrorCatch();
                    })
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone 
                    twoToneColor="#d48806"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Fotografia - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Fotografia')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="idArchivo"
                                type="text"
                                id="idArchivo"
                                size="small"
                                value={this.state.archivo && this.state.archivo.IdArchivo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Foto">
                            <Image src={'./' + this.state.archivo.Ubicacion}>
                            </Image>
                        </Form.Item>
                        <Form.Item
                            label="Archivo">
                            <Input
                                style={{ border: 0 }}
                                name="archivos"
                                id="archivos"
                                type="file"
                                size="small"
                                accept=".jpeg, .png, .jpg"                                                           
                                onChange={this.handleFileChange}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Portfolio"
                            required
                        >
                            <Select
                                name="idPortfolio"
                                id="idPortfolio"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        archivo: {
                                            ...this.state.archivo,
                                            idPortfolio: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.archivo && this.state.archivo.idPortfolio) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar portfolio </label></Option>
                                {this.state.portfolio.map(elemento => (
                                    <Option key={elemento.idPortfolio} value={elemento.idPortfolio}>{elemento.nombreLugar}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un portfolio')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Modificar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Modificar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}