import React, { Component } from 'react';
import Chart from 'chart.js/auto'
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { Bar } from 'react-chartjs-2'; //npm install --save react-chartjs-2 chart.js (instalar)
import axios from 'axios';   //npm install axios (instalar)

export default class DiasPorAnioUsuario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            total: [],
            anio: [],
            idUsuario: ''
        }
    };

    peticion = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        await axios.get('Alta/DiasPorMes?idUsuario=' + 2, config)
            .then(response => {
                let altas = response.data.data
                altas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                    let date = moment(new Date(el.fechaVencimiento))
                    return el.fechaVencimiento = date.format("MM-YYYY");
                })
                var respuesta = response.data.data;
                var auxTotal = [], auxFecha = [];
                respuesta.map(elemento => ((
                    auxTotal.push(elemento.dias),
                    auxFecha.push(elemento.fechaVencimiento)
                )));
                this.setState({
                    total: auxTotal,
                    fecha: auxFecha
                }); // Cargo en data los resultados de la consulta               
            })
    }

    componentDidMount() {
        this.peticion();
    }

    render() {
        const data = {

            labels: this.state.fecha,/* datos de la X */
            datasets: [{
                label: 'Total dias trabajados por mes',
                backgroundColor: '#096dd9',
                borderColor: '#0050b3',
                fill: false,
                lineTension: 0,
                pointRadius: 5,
                pointStyle: 'rectRot',
                borderWidth: 1,
                hoverBackgroundColor: '#1890ff',
                hoverBorderColor: 'white',
                scaleShowGridlLines: false,
                titleDisplay: true,
                data: this.state.total /* datos de la y */
            }]
        };

        const options = {
            animation: true,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    onHover: 'handleHover',
                    onLeave: 'handleLeave',
                },
            },
            scales: {
                y: {
                    display: true,
                    beginAtZero: true,

                    title: {
                        display: true,
                        text: 'Dias',
                        padding: { top: 0, left: 0, right: 0, bottom: 30 }
                    },
                    grid: {
                        display: false
                    }
                },
                x: {
                    display: true,
                    title: {
                        display: true,
                        text: 'Mes',
                        padding: { top: 20, left: 0, right: 0, bottom: 0 }
                    },
                    grid: {
                        display: false
                    },
                }
            }
        }

        return (
            <div>
                <div style={{ width: '500px', height: '350px', margin: 'auto', alignContent: 'center' }} >
                    <h2 style={{ textAlign: 'center', paddingTop: '10px' }}>Dias trabajados</h2>
                    <Bar data={data} options={options} />
                </div>
            </div>
        )
    }
}