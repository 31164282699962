import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1, mostrarAlertaModificar } from '../../Paginas/Mensajes';
import { esNumero, esNumeroPunto } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class ModificarFactura extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        error: false,
        detalleError: '',
        validacion: false,
        validacionBase: false,
        validacion4: false,
        validacionGasto4: false,
        validacionImponible4: false,
        validacionGasto10: false,
        validacionImponible10: false,
        validacionGasto21: false,
        validacionImponible21: false,
        validacionGastoSinIva: false,
        validacionImponibleSinIva: false,
        validacion10: false,
        validacion21: false,
        validacionSinIva: false,
        validacionF: false,
        validacionS: false,
        fechaModificada: false,
        factura: {
            idFactura: '',
            numero: '',
            fecha: '',
            baseImponible: '',
            gastos4Porciento: '',
            imponible4Porciento: '',
            gastos10Porciento: '',
            imponible10Porciento: '',
            gastos21Porciento: '',
            imponible21Porciento: '',
            gastosSinIva: '',
            imponibleSinIva: '',
            idTrabajo: '',
            idEstado: '',
            idCondicion: '',
        },
        DetalleTrabajo: '',
        Cliente: '',
        condicion: [],
    };

    showDrawer = () => {
        this.cargarDatos();
        this.obtenerCondicionSelect();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionBase: false,
            validacion4: false,
            validacionGasto4: false,
            validacionImponible4: false,
            validacionGasto10: false,
            validacionImponible10: false,
            validacionGasto21: false,
            validacionImponible21: false,
            validacionGastoSinIva: false,
            validacionImponibleSinIva: false,
            validacion10: false,
            validacion21: false,
            validacionSinIva: false,
            validacionF: false, // desaparece el cartel de validacion al cancelar (Fecha Inicio)  
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)          
        });
    };

    obtenerCondicionSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios // Cargo los datos en el select de roles
                .get('CondicionPago', config)
                .then(response => {
                    this.setState({ condicion: response.data.data })
                })
                .catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    cargarDatos = async () => {
        await this.setState({
            factura: {
                idFactura: this.props.filaSeleccionada.idFactura,
                numero: this.props.filaSeleccionada.numero,
                fecha: this.props.filaSeleccionada.fecha,
                baseImponible: (this.props.filaSeleccionada.baseImponible - this.props.filaSeleccionada.imponible21Porciento).toFixed(2), // Coloca 2 decimales despues de la coma
                gastos4Porciento: this.props.filaSeleccionada.gastos4Porciento,
                imponible4Porciento: this.props.filaSeleccionada.imponible4Porciento === 0 ? '' : this.props.filaSeleccionada.imponible4Porciento,
                gastos10Porciento: this.props.filaSeleccionada.gastos10Porciento,
                imponible10Porciento: this.props.filaSeleccionada.imponible10Porciento === 0 ? '' : this.props.filaSeleccionada.imponible10Porciento,
                gastos21Porciento: this.props.filaSeleccionada.gastos21Porciento,
                imponible21Porciento: this.props.filaSeleccionada.imponible21Porciento === 0 ? '' : this.props.filaSeleccionada.imponible21Porciento,
                gastosSinIva: this.props.filaSeleccionada.gastosSinIva,
                imponibleSinIva: this.props.filaSeleccionada.imponibleSinIva === 0 ? '' : this.props.filaSeleccionada.imponibleSinIva,
                idTrabajo: this.props.filaSeleccionada.idTrabajo,
                idEstado: this.props.filaSeleccionada.idEstado,
                idCondicion: this.props.filaSeleccionada.idCondicion,
            },
            DetalleTrabajo: this.props.filaSeleccionada.unObjTrabajo.nombre, // Guardo la descripcion del trabajo que viene del listado trabajo
            Cliente: this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre, // Guardo el nombre del cliente que viene de listado trabajo          
        });
        this.obtenerCondicionSelect();
    }

    validacionForm = async () => {
        if (!esNumero(this.state.factura.numero)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
        if (!esNumeroPunto(this.state.factura.baseImponible)) {
            await this.setState({ validacionBase: false })
        }
        else {
            await this.setState({ validacionBase: true })
        }
        if (this.state.factura.gastos4Porciento !== '' && this.state.factura.imponible4Porciento === '') {
            await this.setState({ validacionGasto4: true })
        }
        else {
            await this.setState({
                validacionGasto4: false
            })
        }
        if (this.state.factura.gastos4Porciento === '' && this.state.factura.imponible4Porciento !== '') {
            await this.setState({ validacionImponible4: true })
        }
        else {
            await this.setState({
                validacionImponible4: false
            })
        }
        if (!esNumeroPunto(this.state.factura.imponible4Porciento)) {
            await this.setState({ validacion4: false })
        }
        else {
            await this.setState({ validacion4: true })
        }
        if (this.state.factura.gastos10Porciento !== '' && this.state.factura.imponible10Porciento === '') {
            await this.setState({ validacionGasto10: true })
        }
        else {
            await this.setState({
                validacionGasto10: false
            })
        }
        if (this.state.factura.gastos10Porciento === '' && this.state.factura.imponible10Porciento !== '') {
            await this.setState({ validacionImponible10: true })
        }
        else {
            await this.setState({
                validacionImponible10: false
            })
        }
        if (!esNumeroPunto(this.state.factura.imponible10Porciento)) {
            await this.setState({ validacion10: false })
        }
        else {
            await this.setState({ validacion10: true })
        }
        if (this.state.factura.gastos21Porciento !== '' && this.state.factura.imponible21Porciento === '') {
            await this.setState({ validacionGasto21: true })
        }
        else {
            await this.setState({
                validacionGasto21: false
            })
        }
        if (this.state.factura.gastos21Porciento === '' && this.state.factura.imponible21Porciento !== '') {
            await this.setState({ validacionImponible21: true })
        }
        else {
            await this.setState({
                validacionImponible21: false
            })
        }
        if (!esNumeroPunto(this.state.factura.imponible21Porciento)) {
            await this.setState({ validacion21: false })
        }
        else {
            await this.setState({ validacion21: true })
        }
        if (this.state.factura.gastosSinIva !== '' && this.state.factura.imponibleSinIva === '') {
            await this.setState({ validacionGastoSinIva: true })
        }
        else {
            await this.setState({
                validacionGastoSinIva: false
            })
        }
        if (this.state.factura.gastosSinIva === '' && this.state.factura.imponibleSinIva !== '') {
            await this.setState({ validacionImponibleSinIva: true })
        }
        else {
            await this.setState({
                validacionImponibleSinIva: false
            })
        }
        if (!esNumeroPunto(this.state.factura.imponibleSinIva)) {
            await this.setState({ validacionSinIva: false })
        }
        else {
            await this.setState({ validacionSinIva: true })
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.factura.idCondicion === '0' || this.state.factura.idCondicion === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFecha = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.factura.fecha === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFecha = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                factura: {
                    ...this.state.factura,
                    fecha: ''
                },
                validacionF: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaModificada: false
            })
        } else {
            await this.setState({
                factura: {
                    ...this.state.factura,
                    fecha: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaModificada: true
            })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            factura: {
                ...this.state.factura,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFecha() }) // Devuleve true si no ingrese fecha, false en caso contrario 
            await this.setState({ validacionS: this.validacionSelect() });
            if (this.state.fechaModificada === false) {
                let fecham = this.state.factura.fecha.split('-').reverse().join('/')
                await this.setState({ // Cago el la fecha invertida 
                    factura: {
                        ...this.state.factura,
                        fecha: fecham,
                    }
                });
            }
            if (this.state.validacionF === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionS === false) { // Si no selecciona la condicion muestro el mensaje de error y no entro al post                      
                    await axios.put('Factura', this.state.factura, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaFacturas(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                        }).catch(error => {
                            if (error.response) {
                                console.log('Factura', error.response);
                                if (error.response.data.errors.fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                    mostrarAlertaError(error.response.data.errors.Fecha[0])
                                    this.setState({
                                        error: true,
                                        detalleError: error.response.data.errors.Fecha[0]
                                    })
                                }
                                else {
                                    mostrarAlertaError(error.response.data.errors[0].detail);
                                }
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ error: false });
                    }} />


                <Drawer
                    title="Factura - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero"
                            hidden
                        >
                            <Input
                                disabled
                                hidden
                                name="idFactura"
                                type="text"
                                id="idFactura"
                                size="small"
                                value={this.state.factura && this.state.factura.idFactura}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nº Factura"
                            required>
                            <Input
                                name="numero"
                                type="text"
                                id="numero"
                                maxLength={10}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.numero}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Cliente"
                        >
                            <Input
                                name="Cliente"
                                type="text"
                                disabled
                                id="Cliente"
                                minLength={5}
                                maxLength={70}
                                size="small"
                                value={this.state && this.state.Cliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Descripcion"
                        >
                            <Input
                                name="Trabajo"
                                type="text"
                                disabled
                                id="Trabajo"
                                minLength={5}
                                maxLength={70}
                                size="small"
                                value={this.state && this.state.DetalleTrabajo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.factura.fecha, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFecha} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Base Imponible"
                            required>
                            <Input
                                name="baseImponible"
                                type="text"
                                id="baseImponible"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.baseImponible}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionBase, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Gastos Iva 4%"
                        >
                            <Input
                                name="gastos4Porciento"
                                type="text"
                                id="gastos4Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.gastos4Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImponible4, 'Colocar el nombre del gasto o borrar imponible')}
                        </Form.Item>
                        <Form.Item
                            label="Imponible Iva 4%"
                        >
                            <Input
                                name="imponible4Porciento"
                                type="text"
                                id="imponible4Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.imponible4Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionGasto4, 'Colocar el imponible del gasto o borrar el nombre')}
                            {mostrarMensajeValidacionFooter(this.state.validacion4, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Gastos Iva 10%"
                        >
                            <Input
                                name="gastos10Porciento"
                                type="text"
                                id="gastos10Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.gastos10Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImponible10, 'Colocar el nombre del gasto o borrar imponible')}
                        </Form.Item>
                        <Form.Item
                            label="Imponible Iva 10%"
                        >
                            <Input
                                name="imponible10Porciento"
                                type="text"
                                id="imponible10Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.imponible10Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionGasto10, 'Colocar el imponible del gasto o borrar el nombre')}
                            {mostrarMensajeValidacionFooter(this.state.validacion10, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Gastos Iva 21%"
                        >
                            <Input
                                name="gastos21Porciento"
                                type="text"
                                id="gastos21Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.gastos21Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImponible21, 'Colocar el nombre del gasto o borrar imponible')}
                        </Form.Item>
                        <Form.Item
                            label="Imponible Iva 21%"
                        >
                            <Input
                                name="imponible21Porciento"
                                type="text"
                                id="imponible21Porciento"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.imponible21Porciento}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionGasto21, 'Colocar el imponible del gasto o borrar el nombre')}
                            {mostrarMensajeValidacionFooter(this.state.validacion21, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Gastos sin Iva "
                        >
                            <Input
                                name="gastosSinIva"
                                type="text"
                                id="gastosSinIva"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.gastosSinIva}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImponibleSinIva, 'Colocar el nombre del gasto o borrar imponible')}
                        </Form.Item>
                        <Form.Item
                            label="Imponible sin Iva "
                        >
                            <Input
                                name="imponibleSinIva"
                                type="text"
                                id="imponibleSinIva"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.factura && this.state.factura.imponibleSinIva}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionGastoSinIva, 'Colocar el imponible del gasto o borrar el nombre')}
                            {mostrarMensajeValidacionFooter(this.state.validacionSinIva, 'El numero no puede contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Condicion"
                            required
                        >
                            <Select
                                name="idCondicion"
                                id="idCondicion"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        factura: {
                                            ...this.state.factura,
                                            idCondicion: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.factura && this.state.factura.idCondicion) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar condicion </label></Option>
                                {this.state.condicion.map(elemento => (
                                    <Option key={elemento.idCondicion} value={elemento.idCondicion}>{elemento.descripcion}</Option>
                                ))}
                            </Select>
                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar una condicion de pago')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion && !this.state.validacionBase && !this.state.validacion4 &&
                                    !this.state.validacion10 && !this.state.validacion21 && !this.state.validacionSinIva &&
                                    !this.state.validacionGasto4 && !this.state.validacionImponible4 && !this.state.validacionGasto10 && !this.state.validacionImponible10 &&
                                    !this.state.validacionGasto21 && !this.state.validacionImponible21 && !this.state.validacionGastoSinIva && !this.state.validacionImponibleSinIva ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}