import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombre, quitoEspacios, primeraMayuscula } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class CrearPortfolio extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoPortfolio: 0,
        error: false,
        validacion: false,
        validacionF: false,
        portfolio: {
            IdPortfolio: '',
            NombreLugar: '',
            Fecha: '',
            IdUsuario: sessionStorage.getItem('IdUsuario') // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js
        },
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionF: false // desaparece el cartel de validacion al cancelar (Fecha)
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos // Obtengo los datos que paso por parametro en el listado pasados en  <CrearPortfolio listaPortfolios={this.peticionGet} datos={this.state.data} />
        )
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo 
        if (this.state.i >= 0) {
            await this.setState({ ultimoPortfolio: arreglo[this.state.i].idPortfolio + 1 }); // obtengo el ultimo IdUsuario y le sumo para colocarlo en el value del Modal        
        }
        else {
            this.setState({ ultimoPortfolio: 1 })
        }        
        await this.setState({
            portfolio: {
                IdPortfolio: this.state.ultimoPortfolio,
                NombreLugar: '',
                Fecha: '',
                IdUsuario: sessionStorage.getItem('IdUsuario')
            }
        });
    }

    validacionForm = async () => {
        if (esNombre(this.state.portfolio.NombreLugar)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    validacionFecha = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.portfolio.Fecha === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChange = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                portfolio: {
                    ...this.state.portfolio,
                    Fecha: ''
                },
                validacionF: true //al colocar esto se muestra el msj de La fecha no puede ser vacia
            })
        } else {
            await this.setState({
                portfolio: {
                    ...this.state.portfolio,
                    Fecha: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false //al colocar esto se borra el msj de La fecha no puede ser vacia
            })
        }
    }

    handleChange = async (evt) => { // es llamado en el input nombreLugar
        const value = evt.target.value
        await this.setState({
            portfolio: {
                ...this.state.portfolio,
                NombreLugar: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFecha() }) // Devuleve true si no ingrese fecha, false en caso contrario
            this.state.portfolio.NombreLugar = quitoEspacios(this.state.portfolio.NombreLugar);// Quita los espacios en blanco al inicio, al final, y los que esten demas entre palabras
            this.state.portfolio.NombreLugar = primeraMayuscula(this.state.portfolio.NombreLugar); // Coloca la primer letra de cada palabra en mayuscula 
            if (this.state.validacionF === false) { // Si no selecciona la fecha muestro el mensaje de error y no entro al post     
                if (this.state.portfolio.NombreLugar !== '') {// verifico que el campo usuario.NombreLugar no sea vacio                       
                    delete this.state.portfolio.IdPortfolio; //Borro el Id ya que la bd lo creo automaticamente                     
                    await axios.post('Portfolio', this.state.portfolio, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaPortfolios(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABPortfolio>
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.errors.Fecha) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                    mostrarAlertaError(error.response.data.errors.Fecha[1])
                                } else {
                                    mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                    this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                                }
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false });
                    }}
                >
                    <PlusOutlined /> Nuevo Portfolio
                </Button>

                <Drawer
                    title="Portfolio - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Portfolio')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="IdPortfolio"
                                type="text"
                                id="IdPortfolio"
                                size="small"
                                value={this.state.portfolio && this.state.portfolio.IdPortfolio}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Lugar"
                            required>
                            <Input
                                name="NombreLugar"
                                type="text"
                                id="NombreLugar"
                                minLength={5}
                                maxLength={70}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.portfolio && this.state.portfolio.NombreLugar}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El portfolio solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}                               
                                value={this.state.portfolio.Fecha !== "" ? moment(this.state.portfolio.Fecha) : null}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChange} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}