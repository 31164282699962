import React, { Component } from "react";
import { FilePdfTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import jsPDF from "jspdf"; //npm i jspdf 
import "jspdf-autotable"; //npm i jspdf jspdf-autotable
import LogoFactura from '../../Logos/LogoFactura.jpg';
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class VerPdf extends Component {

    pdfGenerate = () => {
        let baseImponible = (this.props.filaSeleccionada.baseImponible - this.props.filaSeleccionada.imponible21Porciento).toFixed(2);
       // let retenciones = (baseImponible * 0.15).toFixed(2);
        var numero = String(this.props.filaSeleccionada.numero);
        var doc = new jsPDF('p', 'px', 'a4');
        // Imagen
        doc.addImage(LogoFactura, 'JPG', 30, 20, 180, 60)
        doc.setFontSize(11);
        // Fecha y Numero de Factura
        doc.rect(295, 21, 135, 30); //x,y,largo,ancho
        doc.text('Fecha:', 300, 35); // x,y
        doc.text(this.props.filaSeleccionada.fecha, 380, 35);
        doc.text('Factura:', 300, 45);
        doc.text(numero, 380, 45);
        // Datos Diego        
        doc.rect(25, 106, 185, 75);
        doc.text('Nombre: Diego Gòmez Garra', 30, 120);
        doc.text('Direcciòn: Calle del Doctor Lozano 21. 4D.', 30, 130);
        doc.text('Localidad: Madrid CP: 28038', 30, 141);
        doc.text('NIE: Y4567518-P', 30, 152);
        doc.text('Telèfono: 717 77 20 26', 30, 162);
        doc.text('Mail: diegogomezgarra@gmail.com', 30, 172);
        // Datos Cliente
        doc.rect(25, 201, 280, 75);
        doc.text('Cliente: ', 30, 215);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre, 63, 215);
        doc.text('Nombre: ', 30, 235);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre, 67, 235);
        doc.text('Direccion: ', 30, 245);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.direccion, 71, 245);
        doc.text('Ciudad: ', 30, 255);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.localidad, 65, 255);
        doc.text('Localidad: ', 140, 255);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.provincia, 183, 255);
        doc.text('CIF : ', 30, 265);
        doc.text(this.props.filaSeleccionada.unObjTrabajo.unObjCliente.cif, 53, 265);

        var total = this.props.filaSeleccionada.iva + this.props.filaSeleccionada.iva21Porciento;
        var columns = ["Descripcion", "", "Importe"];
        var data = [
            this.props.filaSeleccionada.unObjTrabajo.fechaInicio !== this.props.filaSeleccionada.unObjTrabajo.fechaFin ?
                [this.props.filaSeleccionada.unObjTrabajo.nombre + ' del ' 
                + moment(this.props.filaSeleccionada.unObjTrabajo.fechaInicio).format(dateFormat)  
                + ' al ' + moment(this.props.filaSeleccionada.unObjTrabajo.fechaFin).format(dateFormat), '', baseImponible ]
                :
                [this.props.filaSeleccionada.unObjTrabajo.nombre + ' el ' 
                + moment(this.props.filaSeleccionada.unObjTrabajo.fechaInicio).format(dateFormat), '', baseImponible],
            
                this.props.filaSeleccionada.gastos4Porciento !== '' ?               
                [this.props.filaSeleccionada.gastos4Porciento, '', (this.props.filaSeleccionada.imponible4Porciento).toFixed(2)] 
                :
                "",

                this.props.filaSeleccionada.gastos10Porciento !== '' ?               
                [this.props.filaSeleccionada.gastos10Porciento, '', (this.props.filaSeleccionada.imponible10Porciento).toFixed(2)] 
                :
                "",

                this.props.filaSeleccionada.gastos21Porciento !== '' ?               
                [this.props.filaSeleccionada.gastos21Porciento, '', (this.props.filaSeleccionada.imponible21Porciento).toFixed(2)] 
                :
                "",

                this.props.filaSeleccionada.gastosSinIva !== '' ?               
                [this.props.filaSeleccionada.gastosSinIva, '', (this.props.filaSeleccionada.imponibleSinIva).toFixed(2)] 
                :
                "",

            ['', '', ''],             
            ['', '', ''],
            ['', 'Base Imponible', (this.props.filaSeleccionada.baseImponible).toFixed(2) ],
            this.props.filaSeleccionada.gastos4Porciento !== '' ? 
            ['', 'IVA 4%', (this.props.filaSeleccionada.iva4Porciento).toFixed(2)]
            :
            ['', 'IVA 4%', "0.00"],
            this.props.filaSeleccionada.gastos10Porciento !== '' ? 
            ['', 'IVA 10%', (this.props.filaSeleccionada.iva10Porciento).toFixed(2)]
            :
            ['', 'IVA 10%', "0.00"],
            ['', 'IVA 21%' , (total).toFixed(2) ],
            ['', 'Retenciones - 15%', this.props.filaSeleccionada.retenciones ],
            ['', '', ''],
            ['', 'Total Factura', (this.props.filaSeleccionada.total).toFixed(2) +' €']];

        doc.autoTable(columns, data,
            { margin: { top: 300 },
               // theme: 'grid',
                styles: {
                   // overflow: 'linebreak',
                    fontSize: 11,
                    valign: 'middle'
                },
                columnStyles: {
                    0: {valign: "top"}, 
                    1: {
                        columnWidth: 100,                       
                        halign: 'left', 
                    },
                    2: {
                        columnWidth: 60,
                        fontStyle: 'bold',
                        halign: 'right', 
                    },                    
                }               
            }
        );

        // Datos Bancarios        
        doc.rect(25, 575, 205, 45);
        doc.text('Datos Bancarios', 30, 590);
        doc.text('Banco: Caja Mar', 30, 600);
        doc.text('Nº de Cuenta: ES02 3058 2901 5827 2001 5760', 30, 610);        
        
        doc.save(this.props.filaSeleccionada.numero+'.pdf')
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <FilePdfTwoTone
                    twoToneColor="#a8071a"
                    style={{ fontSize: '15px' }}
                    onClick={this.pdfGenerate} />
            </div>
        )
    }
}