import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaUsuario extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        usuario: {
            IdUsuario: '',
            Nombre: '',
            Contrasenia: '',
            idRol: ''
        },
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = () => {
        this.setState({
            usuario: {
                IdUsuario: this.props.filaSeleccionada.idUsuario,
                Nombre: this.props.filaSeleccionada.nombre,
                Contrasenia: this.props.filaSeleccionada.contrasenia,
                idRol: this.props.filaSeleccionada.idRol
            }
        });
    }

    // DELETE: api/Usuario/5
    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('Usuario/' + this.state.usuario.IdUsuario, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaUsuarios();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                {/* Muestra el boton modificar en todos los casos menos cuando el usuario logeado no es manager, impido que me eliminen el usuario administrador si no es manager */}
                {sessionStorage.getItem('Usuario') === 'Manager' ? //Si es manager muestra el boton eliminar 
                    <DeleteTwoTone
                        twoToneColor="#cf1322"
                        style={{ fontSize: '15px' }}
                        onClick={() => {
                            this.showModal();
                        }} />
                    :
                    this.props.filaSeleccionada.idUsuario !== 1 ? // Si es manager no bloquea el boton Eliminar de aquel usuario que es el administrador ( es decir el manager)
                        <DeleteTwoTone
                            twoToneColor="#cf1322"
                            style={{ fontSize: '15px' }}
                            onClick={() => {
                                this.showModal();
                            }} />
                        :
                        <DeleteTwoTone
                            twoToneColor="#bfbfbf"
                            style={{ fontSize: '15px' }}/>// Si no es manager, bloquea el boton Eliminar solo de aquel usuario que es el administrador ( es decir el manager)
                }

                <Modal
                    visible={this.state.visible}
                    title="Usuario - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar el usuario <b>{this.state.usuario && this.state.usuario.Nombre}</b>
                </Modal>
            </div>
        )
    }
}
