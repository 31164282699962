import React, { Component } from 'react'
import '../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { 
    InfoCircleTwoTone, 
    EditTwoTone, 
    DeleteTwoTone, 
    ToolTwoTone, 
    DiffTwoTone, 
    EuroCircleTwoTone, 
    FilePdfTwoTone,
    ContainerTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

const tab = <>&nbsp;&nbsp;&nbsp;</>;

export default class Ayuda extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions colon='false' size="small" column={1} title="Ayuda - Botones" contentStyle={{ color: "slategray" }}>
                    <Descriptions.Item > <EditTwoTone twoToneColor="#d48806" style={{ fontSize: '15px' }} />{tab} : Editar registros </Descriptions.Item>
                    <Descriptions.Item > <DeleteTwoTone twoToneColor="#cf1322" style={{ fontSize: '15px' }} />{tab} : Borrar registros </Descriptions.Item>
                    <Descriptions.Item> <InfoCircleTwoTone twoToneColor="#7cb305" style={{ fontSize: '15px' }} /> {tab} : Ficha con todos los datos </Descriptions.Item>
                    <Descriptions.Item > <ToolTwoTone twoToneColor="#1890ff" style={{ fontSize: '15px' }} />{tab} : Ingresar nuevo trabajo asociado al cliente </Descriptions.Item>
                    <Descriptions.Item > <DiffTwoTone twoToneColor="#1890ff" style = {{fontSize:'15px'}} />{tab} : Ingresar nueva factura asociada al trabajo </Descriptions.Item>
                    <Descriptions.Item > <DiffTwoTone twoToneColor="#bfbfbf" style = {{fontSize:'15px'}} />{tab} : Trabajo facturado </Descriptions.Item>
                    <Descriptions.Item > <EuroCircleTwoTone twoToneColor="#1890ff" style = {{fontSize:'15px'}} />{tab} : Ingresar nuevo pago asociado a factura </Descriptions.Item>
                    <Descriptions.Item > <EuroCircleTwoTone twoToneColor="#bfbfbf" style = {{fontSize:'15px'}} />{tab} : Factura pagada </Descriptions.Item>
                    <Descriptions.Item > <FilePdfTwoTone twoToneColor="#a8071a" style = {{fontSize:'15px'}} />{tab} : Guardar Factura en Pdf </Descriptions.Item>
                    <Descriptions.Item > <ContainerTwoTone twoToneColor="#1890ff" style = {{fontSize:'15px'}} />{tab} : Ingresar nueva alta asociado a categoria </Descriptions.Item>
                    
                    
                </Descriptions>,
            duration: 10,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <a onClick={() => this.openNotification()}>Ayuda</a>
        )
    }
}