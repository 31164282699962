import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification, Image } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

export default class DetalleArchivo extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Fotografia - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idArchivo} </Descriptions.Item>
                    <Descriptions.Item label="Foto" labelStyle={{ fontWeight: 600 }}>
                        <Image
                            src={'./' + this.props.filaSeleccionada.ubicacion}
                            preview={false}>
                        </Image>
                    </Descriptions.Item>
                    <Descriptions.Item label="Lugar" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjPortfolio.nombreLugar}</Descriptions.Item>

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <div>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />  
            </div>
        )
    }
}