import Jwt from 'jsonwebtoken'; // instalar npm i jsonwebtoken

export const expiredToken = () => {
    const token = localStorage.getItem('Token');
    var isExpired = true;
    var decodedToken = Jwt.decode(token, { complete: true });
    var dateNow = new Date();
    if (decodedToken!= null) {
        if (decodedToken.payload.exp > Math.round(dateNow.getTime() / 1000, 0)) // Le quito los milisegundos a la fecha para poder compararla 
            isExpired = false;
    }

    return isExpired;
};

export const cerrarSesion = () => {
    localStorage.clear();
    return (
        window.location.href = ('/')
    )
}
