import React, { Component } from 'react';
import { Table, Input, Button, Space, Image } from 'antd'; //npm i antd (instalar)
import Highlighter from 'react-highlight-words'; // npm i react-highlight-words (instalar para que aparezcan pintadas en amarillo los resultados del search)
import { SearchOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import get from "lodash.get"; // npm i --save lodash.get
import isequal from "lodash.isequal"; //npm i --save lodash.isequal
import CrearFotografia from './CrearFotografia';
import ModificarFotografia from './ModificarFotografia';
import BajaFotografia from './BajaFotografia';
import DetalleArchivo from './DetalleArchivo';
import {mostrarErrorCatch} from "../../Paginas/Mensajes";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class ListadoFotografias extends Component {

    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        paginado: [],
        pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
    };

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    peticionGet = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            axios.get('Archivo?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos
            .then(response => {
                this.setState({ 
                    data: response.data.data,
                    paginado: response.data.paginado,
                 }); // Cargo en data los resultados de la consulta               
            }).catch(error => {
                error = mostrarErrorCatch();
            });
        }
        else {
            cerrarSesion();
        }
    }

    //Esto es el ciclo de vida, en clases utilizamos esto y en funciones se usa useEffect().
    // Pinta en pantalla la consulta
    componentDidMount() {
        this.peticionGet();        
    }   

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return get(record, dataIndex)
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => { //hay que modificar con respecto al original (ListadoRol) , para que busque dentro de unObjRol
            return isequal(this.state.searchedColumn, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            );
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idArchivo',
                key: 'idArchivo',
                width: '15%',
                ...this.getColumnSearchProps('idArchivo'),
                sorter: (a, b) => a.idArchivo - b.idArchivo,
                sortDirections: ['descend', 'ascend'],
            },            
            {
                title: 'Portfolio',
                dataIndex: ['unObjPortfolio', 'nombreLugar'],
                key: 'Tipo1',
                width: '20%',
                ...this.getColumnSearchProps(['unObjPortfolio', 'nombreLugar']),
                sorter: (a, b) => a.unObjPortfolio.nombreLugar.localeCompare(b.unObjPortfolio.nombreLugar),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fotografia',
                dataIndex: 'ubicacion',
                key: 'ubicacion',
                render: (value) => (
                    <Image
                        src= {"./" + value} 
                        width = '80px' 
                        preview = {false}                                       
                    />
                ),
                width: '10%',
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '50%',
                render: fila => (
                    <>
                        <div>
                        <label>
                                <ModificarFotografia
                                    filaSeleccionada={fila}
                                    listaArchivos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaFotografia
                                    filaSeleccionada={fila}
                                    listaArchivos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleArchivo
                                    filaSeleccionada={fila}
                                />
                            </label>                           
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Fotografias</h3>
                </div>
                <br />
                <CrearFotografia
                    listaArchivos={this.peticionGet}
                    datos={this.state.data} />
                <br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange  }}
                    columns={columns}
                    dataSource={this.state.data}
                    rowKey={row => row.idArchivo} />
            </div>
        );
    }
}