import React, { Component } from 'react';
import { Table, Input, Form} from 'antd'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import { esNumero } from '../../Paginas/Validaciones';
import { mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import ModificarPago from './ModificarPago';
import BajaPago from './BajaPago';
import DetallePago from './DetallePago';

export default class ListadoPagos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [], // Aca se guardaran todos los resultados de la consulta que vienen desde la api en peticion get
            paginado: [], // Aca se guardara todos los resultados de la paginacion que vienen desde la api en la peticion get
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table
            factura: '', // usado para realizar la busqueda por numero factura
            validacion: false
        }
    };

    validacionForm = async () => {
        if (!esNumero(this.state.factura)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
    }

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    handleChangeSearch = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            [name]: value
        });
        this.validacionForm();
        if (!this.state.validacion && this.state.pagina === 0) { // Debo validar ademas de que ingrese valores correctos que se encuentre en la pagina 0 porque si estoy en la pagina 2 por ej y escribo un nombre no lo va a encontrar            
            this.peticionGet();
        }
        else {
            this.setState({ //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
                pagina: 0
            })
            this.peticionGet();
        }
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.factura !== '') {
            return axios.get('Pago?numero=' + this.state.factura + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        } else {
            return axios.get('Pago?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = () => {
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let pagos = response.data.data
                    pagos.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.
                        let dateFecha = moment(new Date(el.fecha));                        
                        return el.fecha = dateFecha.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                                                                                                                      
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        } 
    }

    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idPago',
                key: 'idPago',
                width: '7%',                
                sorter: (a, b) => a.idPago - b.idPago,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nº Factura',
                dataIndex: ['unObjFactura','numero'],
                key: 'numero',
                width: '12%',                
                sorter: (a, b) => a.unObjFactura.numero - b.unObjFactura.numero,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjFactura','unObjTrabajo', 'unObjCliente', 'nombre'],
                key: 'nombre',
                width: '30%',                
                sorter: (a, b) => a.unObjFactura.unObjTrabajo.unObjCliente.nombre.localeCompare(b.unObjFactura.unObjTrabajo.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },                     
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '14%',               
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },            
            {
                title: 'Monto €',
                dataIndex: 'monto',
                key: 'monto',
                width: '12%',                
                sorter: (a, b) => a.monto - b.monto,
                sortDirections: ['descend', 'ascend'],
                render: fila => fila.toFixed(2)
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                        <label>
                                <ModificarPago
                                    filaSeleccionada={fila}
                                    listaPagos={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaPago
                                    filaSeleccionada={fila}
                                    listaPagos={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetallePago
                                    filaSeleccionada={fila}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Pagos</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>

                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <Input.Search
                                name="factura"
                                type="text"
                                id="factura"
                                size="small"
                                maxLength={70}
                                placeholder="Buscar Factura X Numero"
                                onChange={this.handleChangeSearch}
                                value={this.state.factura}
                            >
                            </Input.Search>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'La factura solo debe contener numeros')}
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}                   
                    columns={columns.filter(col => col.dataIndex !== 'idPago')} //Ocluto 1 o varias columnas
                    dataSource={this.state.data}
                    rowKey={row => row.idPago} />
            </div>
        );
    }
}