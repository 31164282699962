import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaModificar, mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1 } from '../../Paginas/Mensajes';
import { esNombreNumeros, quitoEspacios } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class ModificarTrabajo extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoTrabajo: 0,
        error: false,
        detalleError: '',
        validacion: false,
        validacionF: false,
        validacionFFin: false,
        fechaInicioModificada: false, // Utilizo esta variable solo en el caso que al tocar el boton editar deje la misma fecha, asi la invierto dentro del put para enviarla a la Bd
        fechaFinModificada: false, // Utilizo esta variable solo en el caso que al tocar el boton editar deje la misma fecha, asi la invierto dentro del put para enviarla a la Bd
        trabajo: {
            IdTrabajo: '',
            Nombre: '',
            FechaInicio: '',
            FechaFin: '',
            Facturado: '',
            IdCliente: '',
            IdUsuario: sessionStorage.getItem('IdUsuario') // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js
        },
        NombreCliente: ''
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionF: false, // desaparece el cartel de validacion al cancelar (Fecha Inicio)
            validacionFFin: false // desaparece el cartel de validacion al cancelar (Fecha Fin)
        });
    };

    cargarDatos = async () => {
        await this.setState({
            trabajo: {
                IdTrabajo: this.props.filaSeleccionada.idTrabajo,
                Nombre: this.props.filaSeleccionada.nombre,
                FechaInicio: this.props.filaSeleccionada.fechaInicio,
                FechaFin: this.props.filaSeleccionada.fechaFin,
                Facturado: this.props.filaSeleccionada.facturado,
                IdCliente: this.props.filaSeleccionada.idCliente,
                IdUsuario: sessionStorage.getItem('IdUsuario'), // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js                  
            }, 
            NombreCliente: this.props.filaSeleccionada.unObjCliente.nombre          
        });
    }

    validacionForm = async () => {
        if (esNombreNumeros(this.state.trabajo.Nombre)) {
            await this.setState({ validacion: true })
        }
        else {
            await this.setState({ validacion: false })
        }
    }

    validacionFechaInicio = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.trabajo.FechaInicio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFechaFin = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.trabajo.FechaFin === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFechaInicio = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                trabajo: {
                    ...this.state.trabajo,
                    FechaInicio: ''
                },
                validacionF: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaInicioModificada: false
            })
        } else {
            await this.setState({
                trabajo: {
                    ...this.state.trabajo,
                    FechaInicio: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaInicioModificada: true
            })
        }
    }

    onChangeFechaFin = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                trabajo: {
                    ...this.state.trabajo,
                    FechaFin: ''
                },
                validacionFFin: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaFinModificada: false
            })
        } else {
            await this.setState({
                trabajo: {
                    ...this.state.trabajo,
                    FechaFin: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionFFin: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaFinModificada: true
            })
        }
    }

    handleChange = async (evt) => { // es llamado en el input nombreLugar
        const value = evt.target.value
        await this.setState({
            trabajo: {
                ...this.state.trabajo,
                Nombre: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFechaInicio() }) // Devuleve true si no ingrese fecha, false en caso contrario
            await this.setState({ validacionFFin: this.validacionFechaFin() }) // Devuleve true si no ingrese fecha, false en caso contrario
            if (this.state.fechaInicioModificada === false) {
                let fecham = this.state.trabajo.FechaInicio.split('-').reverse().join('/')
                await this.setState({ // Cago el la fecha invertida 
                    trabajo: {
                        ...this.state.trabajo,
                        FechaInicio: fecham,
                    }
                });
            }   
            if (this.state.fechaFinModificada === false) {
                let fecham = this.state.trabajo.FechaFin.split('-').reverse().join('/')
                await this.setState({ // Cago el la fecha invertida 
                    trabajo: {
                        ...this.state.trabajo,
                        FechaFin: fecham,
                    }
                });
            }                
            this.state.trabajo.Nombre = quitoEspacios(this.state.trabajo.Nombre);// Quita los espacios en blanco al inicio, al final, y los que esten demas entre palabras
           // this.state.trabajo.Nombre = primeraMayuscula(this.state.trabajo.Nombre); // Coloca la primer letra de cada palabra en mayuscula 
            if (this.state.validacionF === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionFFin === false) {// Si no selecciona la fecha de Fin muestro el mensaje de error y no entro al post 
                    if (this.state.trabajo.Nombre !== '') {// verifico que el campo usuario.NombreLugar no sea vacio                                         
                        await axios.put('Trabajo', this.state.trabajo, config) // Inserta en la Base de datos el nuevo registro
                            .then(response => {
                                this.props.listaTrabajos(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                                this.onClose(); // Cierra el modal luego del ingreso  
                                mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                            }).catch(error => {
                                if (error.response) {
                                    if (error.response.data.errors.FechaInicio) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                        mostrarAlertaError(error.response.data.errors.FechaInicio[0])
                                        this.setState({
                                            error: true,
                                            detalleError: error.response.data.errors.FechaInicio[0]})
                                    }
                                }
                            }).catch(errorServer => {
                                errorServer = mostrarErrorCatch();
                            })
                    }
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone 
                    twoToneColor="#d48806"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ 
                            error: false,
                            fechaInicioModificada: false,
                            fechaFinModificada:false });
                    }} />
                
                <Drawer
                    title="Trabajo - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="IdTrabajo"
                                type="text"
                                id="IdTrabajo"
                                size="small"
                                value={this.state.trabajo && this.state.trabajo.IdTrabajo}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Cliente"
                            >
                            <Input
                                name="Cliente"
                                type="text"
                                disabled
                                id="Cliente"
                                minLength={5}
                                maxLength={70}
                                size="small"                               
                                value={this.state && this.state.NombreCliente}
                                
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Descripcion"
                            required>
                            <Input
                                name="Nombre"
                                type="text"
                                id="Nombre"
                                minLength={5}
                                maxLength={70}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.trabajo && this.state.trabajo.Nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'La descripcion solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha Inicio"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.trabajo.FechaInicio, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaInicio} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha Fin"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.trabajo.FechaFin, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaFin} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionFFin, 'Debe seleccionar una fecha')}
                        </Form.Item>                        
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}