import React, { Component } from 'react'
import { mostrarAlertaEliminado, mostrarExisteEnOtraTabla, mostrarErrorCatch } from '../../Paginas/Mensajes';
import axios from 'axios'; //npm install axios (instalar)
import { Modal, Button } from 'antd'; //npm i antd (instalar)
import { DeleteTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

export default class BajaFactura extends Component {

    state = {
        visible: false, // Abre o cierra el modal eliminar 
        factura: {
            idFactura: '',
            numero: '',
            fecha: '',
            baseImponible: '',
            idTrabajo: '',
            idEstado: '',
            idCondicion: '',
        },        
        Cliente: this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre, 
    };

    showModal = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    cargarDatos = async () => {
        await this.setState({
            factura: {
                idFactura: this.props.filaSeleccionada.idFactura,
                numero: this.props.filaSeleccionada.numero,
                fecha: this.props.filaSeleccionada.fecha,
                baseImponible: this.props.filaSeleccionada.baseImponible,
                idTrabajo: this.props.filaSeleccionada.idTrabajo,
                idEstado: this.props.filaSeleccionada.idEstado,
                idCondicion: this.props.filaSeleccionada.idCondicion,
            },
            DetalleTrabajo: this.props.filaSeleccionada.unObjTrabajo.nombre, // Guardo la descripcion del trabajo que viene del listado trabajo
            Cliente: this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre, // Guardo el nombre del cliente que viene de listado trabajo          
        });
    }

    // DELETE: api/Usuario/5
    peticionDelete = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.delete('Factura/' + this.state.factura.idFactura, config)
                .then(response => {
                    this.onClose();
                    mostrarAlertaEliminado();
                    this.props.listaFacturas();
                }).catch(error => {
                    if (error.response) {
                        mostrarExisteEnOtraTabla(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.onClose();
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                    this.onClose();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <DeleteTwoTone 
                    twoToneColor="#cf1322"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showModal();
                    }} />

                <Modal
                    visible={this.state.visible}
                    title="Factura - Eliminar"
                    onCancel={this.onClose}
                    centered
                    maskClosable={false}
                    footer={[
                        <Button onClick={this.onClose}> No </Button>,
                        <Button type='primary' danger onClick={() => this.peticionDelete()}> Si </Button>
                    ]}
                >
                    Esta seguro que desea eliminar la factura <b>{this.state.factura && this.state.factura.numero}</b> correspondiente al cliente <b>{this.state && this.state.Cliente}</b>
                </Modal>
            </div>
        )
    }
}