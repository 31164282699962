import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker } from 'antd'; //npm i antd (instalar)
import { ContainerTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaExito, mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1 } from '../../Paginas/Mensajes';
import { esNombreNumeros, esNumeroPunto, primeraMayuscula, todasMayusculas, quitoEspacios } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class CrearAlta extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        error: false,
        detalleError: '',
        validacion: false,
        validacionF: false,
        validacionFFin: false,
        validacionProyecto: false,
        validacionImporte: false,
        validacionImporteSinImpuestos: false,
        alta: {
            IdAlta: '',
            Empresa: '',
            FechaInicio: '',
            FechaVencimiento: '',
            ImporteSinImpuestos: '',            
            Importe: '',            
            Proyecto: '',
            IdCategoria: '',
            IdUsuario: sessionStorage.getItem('IdUsuario') // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js
        },
        descripcionCategoria: ''
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Empresa)
            validacionF: false, // desaparece el cartel de validacion al cancelar (Fecha Inicio)
            validacionFFin: false, // desaparece el cartel de validacion al cancelar (Fecha Vencimiento)
            validacionImporte: false, // desaparece el cartel de validacion al cancelar (Importe)
            validacionImporteSinImpuestos: false, // desaparece el cartel de validacion al cancelar (Importe sin Impuestos)
            validacionProyecto: false // desaparece el cartel de validacion al cancelar (Proyecto)
        });
    };

    cargarDatos = async () => {
        await this.setState({
            alta: {
                IdAlta: '',
                Empresa: '',
                FechaInicio: '',
                FechaVencimiento: '', 
                ImporteSinImpuestos: 0,              
                Importe: '',                
                Proyecto: '',
                IdCategoria: this.props.filaSeleccionada.idCategoria,
                IdUsuario: sessionStorage.getItem('IdUsuario') // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js
            },
            descripcionCategoria: this.props.filaSeleccionada.descripcion
        });
    }

    validacionForm = async () => {
        if (esNombreNumeros(this.state.alta.Empresa)) {
            await this.setState({ validacion: true })
        }
        else {
            await this.setState({ validacion: false })
        }
        if (!esNumeroPunto(this.state.alta.Importe)) {
            await this.setState({ validacionImporte: false })
        }
        else {
            await this.setState({ validacionImporte: true })
        }
        if (!esNumeroPunto(this.state.alta.ImporteSinImpuestos)) {
            await this.setState({ validacionImporteSinImpuestos: false })
        }
        else {
            await this.setState({ validacionImporteSinImpuestos: true })
        }
        if (esNombreNumeros(this.state.alta.Proyecto)) {
            await this.setState({ validacionProyecto: true })
        }
        else {
            await this.setState({ validacionProyecto: false })
        }
    }

    validacionFechaInicio = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.alta.FechaInicio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFechaFin = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.alta.FechaVencimiento === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFechaInicio = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaInicio: ''
                },
                validacionF: true //al colocar esto se muestra el msj de La fecha no puede ser vacia
            })
        } else {
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaInicio: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false //al colocar esto se borra el msj de La fecha no puede ser vacia
            })
        }
    }

    onChangeFechaFin = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaVencimiento: ''
                },
                validacionFFin: true //al colocar esto se muestra el msj de La fecha no puede ser vacia
            })
        } else {
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaVencimiento: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionFFin: false //al colocar esto se borra el msj de La fecha no puede ser vacia
            })
        }
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            alta: {
                ...this.state.alta,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    conversiones = () => {
        this.state.alta.Empresa = quitoEspacios(this.state.alta.Empresa);
        this.state.alta.Empresa = todasMayusculas(this.state.alta.Empresa);
        this.state.alta.Proyecto = quitoEspacios(this.state.alta.Proyecto);
        this.state.alta.Proyecto = primeraMayuscula(this.state.alta.Proyecto);        
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFechaInicio() }) // Devuleve true si no ingrese fecha, false en caso contrario
            await this.setState({ validacionFFin: this.validacionFechaFin() }) // Devuleve true si no ingrese fecha, false en caso contrario
            this.conversiones();
           // this.state.trabajo.Nombre = primeraMayuscula(this.state.trabajo.Nombre); // Coloca la primer letra de cada palabra en mayuscula 
            if (this.state.validacionF === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionFFin === false) {// Si no selecciona la fecha de Fin muestro el mensaje de error y no entro al post                     
                    delete this.state.alta.IdAlta; //Borro el Id ya que la bd lo creo automaticamente                     
                    await axios.post('Alta', this.state.alta, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaCategorias(); // obtengo la lista de categorias pasada por props desde ListadoCategorias.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.errors.FechaInicio) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                    mostrarAlertaError(error.response.data.errors.FechaInicio[0])
                                    this.setState({
                                        error: true,
                                        detalleError: error.response.data.errors.FechaInicio[0]
                                    })
                                }
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })                    
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div style={{ float: "left" }}>
                <ContainerTwoTone 
                    twoToneColor="#1890ff"
                    style = {{fontSize:'15px'}} 
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.setState({ error: false });
                    }} />

                <Drawer
                    title="Alta - Nueva"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero"
                            hidden>
                            <Input
                                disabled
                                hidden
                                name="IdAlta"
                                type="text"
                                id="IdAlta"
                                size="small"
                                value={this.state.alta && this.state.alta.IdAlta}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Empresa"
                            required>
                            <Input
                                name="Empresa"
                                type="text"
                                id="Empresa"
                                minLength={5}
                                maxLength={80}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Empresa}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'La empresa solo debe contener letras y numeros')}
                        </Form.Item>                        
                        <Form.Item
                            label="Fecha Inicio"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={this.state.alta.FechaInicio !== "" ? moment(this.state.alta.FechaInicio) : null}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaInicio} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha Vencimiento"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                value={this.state.alta.FechaVencimiento !== "" ? moment(this.state.alta.FechaVencimiento) : null}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaFin} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionFFin, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Importe"
                            required>
                            <Input
                                name="Importe"
                                type="text"
                                id="Importe"                               
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Importe}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImporte, 'El importe solo debe contener numeros y punto')}
                        </Form.Item>
                        <Form.Item
                            label="Importe Sin Imp."
                            >
                            <Input
                                name="ImporteSinImpuestos"
                                type="text"
                                id="ImporteSinImpuestos"                               
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.ImporteSinImpuestos}                                
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImporteSinImpuestos, 'El importe solo debe contener numeros y punto')}
                        </Form.Item>
                        <Form.Item
                            label="Proyecto"
                            >
                            <Input
                                name="Proyecto"
                                type="text"
                                id="Proyecto"
                                maxLength={90}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Proyecto} 
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionProyecto, 'El proyecto solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Categoria"
                        >
                            <Input
                                name="Categoria"
                                type="text"
                                disabled
                                id="Categoria"
                                minLength={5}
                                maxLength={50}
                                size="small"
                                value={this.state && this.state.descripcionCategoria}

                            >
                            </Input>
                        </Form.Item>                        
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}