import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space, DatePicker, Checkbox } from 'antd'; //npm i antd (instalar)
import { EditTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'; // npm i moment (instalar para poder formatear las fechas)
import { mostrarAlertaModificar, mostrarAlertaError, mostrarErrorCatch, mostrarMensajeValidacionFooter, mostrarMensajeFooter1 } from '../../Paginas/Mensajes';
import { esNombreNumeros, esNumeroPunto, quitoEspacios, todasMayusculas, primeraMayuscula } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

const dateFormat = 'DD-MM-YYYY'; // Lo voy a utilizar en el date picker para mostrar el formato que quiero

export default class ModificarAlta extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar        
        error: false,
        detalleError: '',
        validacion: false,
        validacionF: false,
        validacionFFin: false,
        fechaInicioModificada: false, // Utilizo esta variable solo en el caso que al tocar el boton editar deje la misma fecha, asi la invierto dentro del put para enviarla a la Bd
        fechaFinModificada: false, // Utilizo esta variable solo en el caso que al tocar el boton editar deje la misma fecha, asi la invierto dentro del put para enviarla a la Bd
        validacionProyecto: false,
        validacionImporte: false,
        validacionImporteSinImpuestos: false,
        alta: {
            IdAlta: '',
            Empresa: '',
            FechaInicio: '',
            FechaVencimiento: '',
            Completado: '',
            ImporteSinImpuestos: '',
            Importe: '',
            Cobrado: '',
            Proyecto: '',
            IdCategoria: '',
            IdUsuario: sessionStorage.getItem('IdUsuario') // Cargo por defecto el usuario ya que sera siempre el mismo que se logea, este se crea en login.js
        },
        descripcionCategoria: ''
    };

    showDrawer = () => {
        this.cargarDatos();
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionF: false, // desaparece el cartel de validacion al cancelar (Fecha Inicio)
            validacionFFin: false, // desaparece el cartel de validacion al cancelar (Fecha Fin)
            validacionImporte: false, // desaparece el cartel de validacion al cancelar (Importe)
            validacionImporteSinImpuestos: false,
            validacionProyecto: false // desaparece el cartel de validacion al cancelar (Proyecto)
        });
    };

    cargarDatos = async () => {
        await this.setState({
            alta: {
                IdAlta: this.props.filaSeleccionada.idAlta,
                Empresa: this.props.filaSeleccionada.empresa,
                FechaInicio: this.props.filaSeleccionada.fechaInicio,
                FechaVencimiento: this.props.filaSeleccionada.fechaVencimiento,
                Completado: this.props.filaSeleccionada.completado,
                ImporteSinImpuestos: this.props.filaSeleccionada.importeSinImpuestos,
                Importe: this.props.filaSeleccionada.importe,               
                Cobrado: this.props.filaSeleccionada.cobrado,
                Proyecto: this.props.filaSeleccionada.proyecto,
                IdCategoria: this.props.filaSeleccionada.idCategoria,
                IdUsuario: this.props.filaSeleccionada.idUsuario       
            },
            descripcionCategoria: this.props.filaSeleccionada.unObjCategoria.descripcion
        });
    }

    validacionForm = async () => {
        if (esNombreNumeros(this.state.alta.Empresa)) {
            await this.setState({ validacion: true })
        }
        else {
            await this.setState({ validacion: false })
        }
        if (!esNumeroPunto(this.state.alta.Importe)) {
            await this.setState({ validacionImporte: false })
        }
        else {
            await this.setState({ validacionImporte: true })
        }
        if (!esNumeroPunto(this.state.alta.ImporteSinImpuestos)) {
            await this.setState({ validacionImporteSinImpuestos: false })
        }
        else {
            await this.setState({ validacionImporteSinImpuestos: true })
        }
        if (esNombreNumeros(this.state.alta.Proyecto)) {
            await this.setState({ validacionProyecto: true })
        }
        else {
            await this.setState({ validacionProyecto: false })
        }
    }

    validacionFechaInicio = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.alta.FechaInicio === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    validacionFechaFin = () => { // si no carga datos en datePicker es true, si carga false
        let valida = false;
        if (this.state.alta.FechaVencimiento === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    onChangeFechaInicio = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaInicio: ''
                },
                validacionF: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaInicioModificada: false
            })
        } else {
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaInicio: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionF: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaInicioModificada: true
            })
        }
    }

    onChangeFechaFin = async (date) => { // Es llamado en el datePicker
        if (date === null) { // si selecciono una fecha en el form, y luego la borro con esto no me ada error, sino da el error invalid Date dentro del datepicker
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaVencimiento: ''
                },
                validacionFFin: true, //al colocar esto se muestra el msj de La fecha no puede ser vacia
                fechaFinModificada: false
            })
        } else {
            await this.setState({
                alta: {
                    ...this.state.alta,
                    FechaVencimiento: moment(date).format("YYYY-MM-DD"), // Vuelvo a cambiarlo el formato a la fecha para ingresarlo a la Bd
                },
                validacionFFin: false, //al colocar esto se borra el msj de La fecha no puede ser vacia
                fechaFinModificada: true
            })
        }
    }

    checkCompletadoChange = async (e) => {
        var check = e.target.checked;
        await this.setState({
            alta: {
                ...this.state.alta,
                Completado: check
            }
        })
    }

    checkCobradoChange = async (e) => {
        var check = e.target.checked;
        await this.setState({
            alta: {
                ...this.state.alta,
                Cobrado: check
            }
        })
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            alta: {
                ...this.state.alta,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => { // es llamado al presionar el boton Insertar en el form
        e.preventDefault();
        if (this.state.tipoModal !== 'insertar') {
            this.peticionPut();
        }
    }

    conversiones = () => {
        this.state.alta.Empresa = quitoEspacios(this.state.alta.Empresa);
        this.state.alta.Empresa = todasMayusculas(this.state.alta.Empresa);
        this.state.alta.Proyecto = quitoEspacios(this.state.alta.Proyecto);
        this.state.alta.Proyecto = primeraMayuscula(this.state.alta.Proyecto);
    }

    peticionPut = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionF: this.validacionFechaInicio() }) // Devuleve true si no ingrese fecha, false en caso contrario
            await this.setState({ validacionFFin: this.validacionFechaFin() }) // Devuleve true si no ingrese fecha, false en caso contrario
            if (this.state.fechaInicioModificada === false) {
                let fecham = this.state.alta.FechaInicio.split('-').reverse().join('/')
                await this.setState({ // Cago el la fecha invertida 
                    alta: {
                        ...this.state.alta,
                        FechaInicio: fecham,
                    }
                });
            }
            if (this.state.fechaFinModificada === false) {
                let fecham = this.state.alta.FechaVencimiento.split('-').reverse().join('/')
                await this.setState({ // Cago el la fecha invertida 
                    alta: {
                        ...this.state.alta,
                        FechaVencimiento: fecham,
                    }
                });
            }
            this.conversiones();
            if (this.state.validacionF === false) { // Si no selecciona la fecha de inicio muestro el mensaje de error y no entro al post 
                if (this.state.validacionFFin === false) {// Si no selecciona la fecha de Fin muestro el mensaje de error y no entro al post                     
                    await axios.put('Alta', this.state.alta, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaAltas(); // obtengo la lista de clientes pasada por props desde ListadoClientes.js (Al clrear el trabajo vuelve a mostrarme la lista de clientes)
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaModificar(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearPortfolio.js
                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.errors.FechaInicio) { // al tener la fecha un array de errores selecciono el que me interesa desde la api
                                    mostrarAlertaError(error.response.data.errors.FechaInicio[0])
                                    this.setState({
                                        error: true,
                                        detalleError: error.response.data.errors.FechaInicio[0]
                                    })
                                }
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <EditTwoTone
                    twoToneColor="#d48806"
                    style={{ fontSize: '15px' }}
                    onClick={() => {
                        this.showDrawer();
                        this.setState({
                            error: false,
                            fechaInicioModificada: false,
                            fechaFinModificada: false
                        });
                    }} />

                <Drawer
                    title="Alta - Modificar"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter1(this.state.error, this.state.detalleError)}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="IdAlta"
                                type="text"
                                id="IdAlta"
                                size="small"
                                value={this.state.alta && this.state.alta.IdAlta}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Empresa"
                            required>
                            <Input
                                name="Empresa"
                                type="text"
                                id="Empresa"
                                minLength={5}
                                maxLength={80}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Empresa}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'La empresa solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha Inicio"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.alta.FechaInicio, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaInicio} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionF, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Fecha Vencimiento"
                            required>
                            <DatePicker
                                style={{ width: '100%' }}
                                defaultValue={moment(this.state.alta.FechaVencimiento, dateFormat)}
                                size='small'
                                placeholder='Seleccionar Fecha'
                                format={dateFormat}
                                onChange={this.onChangeFechaFin} >
                            </DatePicker>
                            {mostrarMensajeValidacionFooter(this.state.validacionFFin, 'Debe seleccionar una fecha')}
                        </Form.Item>
                        <Form.Item
                            label="Finalizado"
                        >
                            <Checkbox
                                name="Completado"
                                id="Completado"
                                size='small'
                                onChange={this.checkCompletadoChange}
                                checked={this.state.alta.Completado}
                            // value={this.state.data && this.state.alta.Completado}
                            >
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Importe"
                            required>
                            <Input
                                name="Importe"
                                type="text"
                                id="Importe"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Importe}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImporte, 'El importe solo debe contener numeros y punto')}
                        </Form.Item>
                        <Form.Item
                            label="Importe Sin Imp."
                            >
                            <Input
                                name="ImporteSinImpuestos"
                                type="text"
                                id="ImporteSinImpuestos"
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.ImporteSinImpuestos}                                
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionImporteSinImpuestos, 'El importe solo debe contener numeros y punto')}
                        </Form.Item>
                        <Form.Item
                            label="Cobrado"
                        >
                            <Checkbox
                                name="Cobrado"
                                id="Cobrado"
                                size='small'
                                onChange={this.checkCobradoChange}
                                checked={this.state.alta.Cobrado}
                            // value={this.state.data && this.state.alta.Cobrado}
                            >
                            </Checkbox>
                        </Form.Item>
                        <Form.Item
                            label="Proyecto"
                            >
                            <Input
                                name="Proyecto"
                                type="text"
                                id="Proyecto"
                                maxLength={90}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.alta && this.state.alta.Proyecto}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionProyecto, 'El proyecto solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Categoria"
                        >
                            <Input
                                name="Categoria"
                                type="text"
                                disabled
                                id="Categoria"
                                minLength={5}
                                maxLength={50}
                                size="small"
                                value={this.state && this.state.descripcionCategoria}

                            >
                            </Input>
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Actualizar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Actualizar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}