import React, { Component } from 'react';
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esCorreo, esNombre, esNombreNumeros, esNumero, primeraMayuscula, quitoEspacios, todasMayusculas } from '../../Paginas/Validaciones';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class CrearCliente extends Component {
    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoCliente: 0,
        validacionNombre: false,
        validacionNombre1: false,
        validacionCif: false,
        validacionDireccion: false,
        validacionLocalidad: false,
        validacionProvincia: false,
        validacionCodigoPostal: false,
        validacionTelefono: false,
        validacionCorreo: false,
        error: false,
        cliente: {
            IdCliente: '',
            Cif: '',
            Nombre: '', // Nombre Empresa
            Nombre1: '', // Nombre Cliente
            Direccion: '',
            Localidad: '',
            Provincia: '',
            CodigoPostal: '',
            Telefono: '',
            Correo: ''
        }
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    validacionForm = async () => {
        if (esNombreNumeros(this.state.cliente.Nombre)) { // Nombre Empresa
            await this.setState({ validacionNombre: true })
        }
        else {
            await this.setState({ validacionNombre: false })
        }
        if (esNombre(this.state.cliente.Nombre1)) { // Nombre Cliente
            await this.setState({ validacionNombre1: false })
        }
        else {
            await this.setState({ validacionNombre1: true })
        }
        if (esNombreNumeros(this.state.cliente.Cif)) {
            await this.setState({ validacionCif: true })
        }
        else {
            await this.setState({ validacionCif: false })
        }
        if (esNombreNumeros(this.state.cliente.Direccion)) {
            await this.setState({ validacionDireccion: true })
        }
        else {
            await this.setState({ validacionDireccion: false })
        }
        if (esNombreNumeros(this.state.cliente.Localidad)) {
            await this.setState({ validacionLocalidad: true })
        }
        else {
            await this.setState({ validacionLocalidad: false })
        }
        if (esNombreNumeros(this.state.cliente.Provincia)) {
            await this.setState({ validacionProvincia: true })
        }
        else {
            await this.setState({ validacionProvincia: false })
        }
        if (esNumero(this.state.cliente.CodigoPostal)) {
            await this.setState({ validacionCodigoPostal: true })
        }
        else {
            await this.setState({ validacionCodigoPostal: false })
        }
        if (esNombreNumeros(this.state.cliente.Telefono)) {
            await this.setState({ validacionTelefono: true })
        }
        else {
            await this.setState({ validacionTelefono: false })
        }
        if (this.state.cliente.Correo !== '') { // Al esta vacio el campo lo valoda como error ya que '' no es direccion de cooreo valida
            if (esCorreo(this.state.cliente.Correo)) {
                await this.setState({ validacionCorreo: false })
            }
            else {
                await this.setState({ validacionCorreo: true })
            }
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo
        if (this.state.i >= 0) {
            await this.setState({ ultimoCliente: arreglo[this.state.i].idCliente + 1 }); // obtengo el ultimo IdRol y le sumo para colocarlo en el value del Modal
        }
        else {
            this.setState({ ultimoCliente: 1 })
        }        
        await this.setState({
            cliente: {
                IdCliente: this.state.ultimoCliente,
                Cif: '',
                Nombre: '',
                Nombre1: '',
                Direccion: '',
                Localidad: '',
                Provincia: '',
                CodigoPostal: '',
                Telefono: '',
                Correo: ''
            },
            validacionCorreo: false
        });
    }

    handleChange = async ({ target }) => {
        const { name, value } = target
        await this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: value
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    conversiones = () => {
        this.state.cliente.Nombre = quitoEspacios(this.state.cliente.Nombre);
        this.state.cliente.Nombre = todasMayusculas(this.state.cliente.Nombre);
        this.state.cliente.Nombre1 = quitoEspacios(this.state.cliente.Nombre1);
        this.state.cliente.Nombre1 = primeraMayuscula(this.state.cliente.Nombre1);
        this.state.cliente.Direccion = quitoEspacios(this.state.cliente.Direccion);
        this.state.cliente.Direccion = primeraMayuscula(this.state.cliente.Direccion);
        this.state.cliente.Localidad = quitoEspacios(this.state.cliente.Localidad);
        this.state.cliente.Localidad = primeraMayuscula(this.state.cliente.Localidad);
        this.state.cliente.Provincia = quitoEspacios(this.state.cliente.Provincia);
        this.state.cliente.Provincia = primeraMayuscula(this.state.cliente.Provincia);
        this.state.cliente.Cif = quitoEspacios(this.state.cliente.Cif);
        this.state.cliente.Cif = todasMayusculas(this.state.cliente.Cif);
        this.state.cliente.Telefono = quitoEspacios(this.state.cliente.Telefono);
        this.state.cliente.Telefono = todasMayusculas(this.state.cliente.Telefono);
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            this.conversiones();
            delete this.state.cliente.IdCliente; //Borro el Id ya que la bd lo creo automaticamente
            await axios.post('Cliente', this.state.cliente, config) // Inserta en la Base de datos el nuevo registro
                .then(response => {
                    this.props.listaClientes(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                    this.onClose(); // Cierra el modal luego del ingreso  
                    mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                }).catch(error => {
                    if (error.response) {
                        mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                        this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                    }
                }).catch(errorServer => {
                    errorServer = mostrarErrorCatch();
                })
        }
        else {
            cerrarSesion();
        }
    }

    render() {
        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false });
                    }}
                >
                    <PlusOutlined /> Nuevo Cliente
                </Button>

                <Drawer
                    title="Cliente - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Cliente')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Número">
                            <Input
                                disabled
                                name="IdCliente"
                                type="text"
                                id="IdCliente"
                                size="small"
                                value={this.state.cliente && this.state.cliente.IdCliente}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Cif"
                            required>
                            <Input
                                name="Cif"
                                type="text"
                                id="Cif"
                                minLength={9}
                                maxLength={9}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Cif}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionCif, 'El cif solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Empresa"
                            required>
                            <Input
                                name="Nombre"
                                type="text"
                                id="Nombre"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNombre, 'El nombre de empresa solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            >
                            <Input
                                name="Nombre1"
                                type="text"
                                id="Nombre1"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Nombre1}
                                
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionNombre1, 'El nombre solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Direccion"
                            required>
                            <Input
                                name="Direccion"
                                type="text"
                                id="Direccion"
                                minLength={6}
                                maxLength={200}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Direccion}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionDireccion, 'La direccion solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Provincia"
                            required>
                            <Input
                                name="Provincia"
                                type="text"
                                id="Provincia"
                                minLength={6}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Provincia}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionProvincia, 'La provincia solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Localidad"
                            required>
                            <Input
                                name="Localidad"
                                type="text"
                                id="Localidad"
                                minLength={6}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Localidad}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionLocalidad, 'La localidad solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Codigo Postal"
                            required>
                            <Input
                                name="CodigoPostal"
                                type="text"
                                id="CodigoPostal"
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.CodigoPostal}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionCodigoPostal, 'El codigo postal solo debe contener numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Telefono"
                            required>
                            <Input
                                name="Telefono"
                                type="text"
                                id="Telefono"
                                minLength={8}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Telefono}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionTelefono, 'El telefono solo debe contener letras y numeros')}
                        </Form.Item>
                        <Form.Item
                            label="Correo"
                        >
                            <Input
                                name="Correo"
                                type="email"
                                id="Correo"
                                maxLength={70}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.cliente && this.state.cliente.Correo}
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionCorreo, 'El correo no es correcto')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacionNombre ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        );
    }
}