import React, { Component } from 'react';
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import axios from 'axios';   //npm install axios (instalar)
import { Row, Col, Divider } from 'antd';
import {
    RightCircleFilled,
    CalendarOutlined,
    EuroOutlined,
    SnippetsOutlined
} from '@ant-design/icons'; //npm i antd (instalar)
import { Link } from 'react-router-dom';
import DiasPorAnio from './DiasPorAnio';
import CobradoPorAnio from './CobradoPorAnio';

const style2 = { background: '#389e0d', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style6 = { background: '#096dd9', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style7 = { background: '#722ed1', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style11 = { background: '#eb2f96', padding: '15px 20px', color: 'white', width: '250px', marginLeft: '20px' };
const style5 = { padding: '15px 20px' };

export default class InformacionEmpleado extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataTotalDias: [],
            dataTotalCobradoPorUsuario: [],
            dataTotalCobradoSinImpuestosPorUsuario: [],
            idUsuario: ''
        }
    };

    peticionTotalDiasPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalDias?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalDias: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionTotalCobradoPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalCobrado?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalCobradoPorUsuario: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/TotalCobradoSinImpuestos?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataTotalCobradoSinImpuestosPorUsuario: (response.data.data).toFixed(2),
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    peticionAltasPendientesPorUsuario = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await axios.get('Usuario/' + sessionStorage.getItem('Usuario') + '/Nombre', config) //Consulto para obtener el rol y asi ver que menu se muestra de acuredro a cada usuario       
                .then(response => {
                    this.setState({
                        idUsuario: response.data.data.idUsuario
                    }); // Cargo en data los resultados de la consulta 
                }).catch(error => {
                    error = mostrarErrorCatch();
                });

            await axios.get('Alta/CantidadAltas?idUsuario=' + this.state.idUsuario, config)
                .then(response => {
                    this.setState({
                        dataAltasPendientesPorUsuario: response.data.data,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionTotalDiasPorUsuario();
        this.peticionTotalCobradoPorUsuario();
        this.peticionAltasPendientesPorUsuario();
    }

    render() {
        return (
            <div>
                <Divider orientation="left">Resumen de Altas</Divider>
                <Row>
                    <Col>
                        <div style={style2}>
                            <SnippetsOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataAltasPendientesPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Trabajos pendientes</label>
                            <p></p>
                            <div style={{ background: '#237804', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style6}>
                            <CalendarOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}>{this.state.dataTotalDias}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Dias Trabajados </label>
                            <p></p>
                            <div style={{ background: '#0050b3', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style7}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalCobradoPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '18px' }}>Total cobrado </label>
                            <p></p>
                            <div style={{ background: '#531dab', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div style={style11}>
                            <EuroOutlined style={{ fontSize: '45px', float: 'right', color: '#ffffff' }} />
                            <strong style={{ fontSize: '20px' }}> € {this.state.dataTotalCobradoSinImpuestosPorUsuario}</strong>
                            <br></br>
                            <label style={{ fontSize: '17px' }}>Total cobrado sin Imp </label>
                            <p></p>
                            <div style={{ background: '#9e1068', textAlign: 'center' }}>
                                <Link to={"/ListadoAltas"} style={{ color: 'white' }}>
                                    Mas Info &nbsp;<RightCircleFilled />
                                </Link>
                            </div>
                        </div>
                        <br></br>
                    </Col>
                </Row>
                <br></br>
                <Divider orientation="left">Graficos</Divider>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" span={12}>
                        <div style={style5}>
                            <DiasPorAnio />
                        </div>
                        <br></br>
                    </Col>
                    <Col>
                        <div style={style5}>
                            <CobradoPorAnio />
                        </div>
                        <br></br>
                        <br></br>
                    </Col>
                </Row>
            </div>
        )
    }
}