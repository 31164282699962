export const esNombre = (value)=>{
    return !/[^A-Za-zñÑáéíóúÁÉÍÓÚ.\s]/.test(value); 
    // valida mayusculas, minusculas, letra ñ y Ñ, y tildes
}

export const esNombreNumeros = (value)=>{
    return /[^A-Za-z0-9ñÑáéíóúÁÉÍÓÚ:.,"\s]/.test(value); 
    // valida mayusculas, minusculas, letra ñ y Ñ, tildes y numeros
}

export const esNombreGuion = (value)=>{
    return !/[^A-Za-zñÑáéíóúÁÉÍÓÚ-\s]/.test(value); 
    // valida mayusculas, minusculas, letra ñ y Ñ, y tildes
}

export const esNumero = (value)=>{
    return /[^0-9\s]/.test(value); 
}

export const esNumeroPunto = (value)=>{
    return /[^0-9.\s]/.test(value); 
}

export const esContrasenia = (value) =>{
    return !/[^A-Za-z0-9ñÑ_-]/.test(value);
    // valida mayusculas, minusculas, letra ñ y Ñ, numeros y guiones - _
}

export const quitoEspacios = (string) =>{
    return string.trim().replace(/\s\s+/g, ' '); 
    // quito todos los espacios que estan demas
}

export const todasMayusculas = (string) =>{
    return string.toUpperCase();
}

export const primeraMayuscula = (string) =>{
    return string.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))); 
    // coloca mayusculas en cada inicio de palabra
}

export const esCorreo = (value)=>{
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value);
}
