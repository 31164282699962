import React, { Component } from 'react'
import { Drawer, Form, Button, Input, Space } from 'antd'; //npm i antd (instalar)
import { PlusOutlined } from '@ant-design/icons'; //npm i antd (instalar)
import { Select } from 'antd'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import { mostrarAlertaExito, mostrarAlertaError, mostrarMensajeFooter, mostrarErrorCatch, mostrarMensajeValidacionFooter } from '../../Paginas/Mensajes';
import { esNombre, esContrasenia, quitoEspacios, primeraMayuscula } from "../../Paginas/Validaciones";
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 12,
    },
};

export default class CrearUsuario extends Component {

    state = {
        visible: false,
        tipoModal: '', // esto es para saber si es insertar o modificar
        i: 0,
        ultimoUsuario: 0,
        error: false,
        validacion: false,
        validacionP: false,
        validacionS: false,
        usuario: {
            IdUsuario: '',
            Nombre: '',
            Contrasenia: '',
            idRol: '',
        },
        rol: [],
    };

    showDrawer = () => {
        this.setState({
            visible: true,
        });
    };

    onClose = () => {
        this.setState({
            visible: false,
            validacion: false, // desaparece el cartel de validacion al cancelar (Nombre)
            validacionP: false, // desaparece el cartel de validacion al cancelar (Contraseña)
            validacionS: false // desaparece el cartel de validacion al cancelar (Select)
        });
    };

    obtenerDatos = () => {
        return (
            this.props.datos
        )
    }

    obtenerRolSelect = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            if (sessionStorage.getItem('Usuario') === 'Manager') {
                axios // Cargo los datos en el select de roles
                    .get('Rol', config)
                    .then(response => {
                        this.setState({ rol: response.data.data })
                    })
                    .catch(error => {
                        error = mostrarErrorCatch();
                    });
            } else {
                axios // Cargo los datos en el select de roles
                    .get('Rol/SinAdministrador', config)
                    .then(response => {
                        this.setState({ rol: response.data.data })
                    })
                    .catch(error => {
                        error = mostrarErrorCatch();
                    });
            }
        }
        else {
            cerrarSesion();
        }
    }

    // en el caso de modificar no necesito resetear los campos ya que si da error necesito que los mismos queden seleccionados
    resertFormCrear = async () => {
        const arreglo = this.obtenerDatos();
        const largo = this.props.datos.length;
        await this.setState({ i: largo - 1 }); // Obtengo la posicion del ultimo dato del arreglo
        if (this.state.i >= 0) {
            await this.setState({ ultimoUsuario: arreglo[this.state.i].idUsuario + 1 }); // obtengo el ultimo IdUsuario y le sumo para colocarlo en el value del Modal
        }
        else {
            this.setState({ ultimoUsuario: 1 })
        }
        await this.setState({
            usuario: {
                IdUsuario: this.state.ultimoUsuario,
                Nombre: '',
                Contrasenia: '',
                idRol: ''
            }
        });
        this.obtenerRolSelect();
    }

    validacionForm = async () => {
        if (esNombre(this.state.usuario.Nombre)) {
            await this.setState({ validacion: false })
        }
        else {
            await this.setState({ validacion: true })
        }
        if (esContrasenia(this.state.usuario.Contrasenia)) {
            await this.setState({ validacionP: false })
        }
        else {
            await this.setState({ validacionP: true })
        }
    }

    validacionSelect = () => { // si no carga datos en select es true, si carga false
        let valida = false;
        if (this.state.usuario.idRol === '0' || this.state.usuario.idRol === '') {
            valida = true;
        }
        else {
            valida = false;
        }
        return valida;
    }

    handleChange = async (evt) => {
        const name = evt.target.name;
        const value = evt.target.value
        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value,
            }
        });
        this.validacionForm();
    }

    handleSubmit = e => {
        e.preventDefault(); // El preventDefault() método cancela el evento si es cancelable, 
        //lo que significa que la acción predeterminada que pertenece al evento no ocurrirá. 
        //Por ejemplo, esto puede ser útil cuando: Al hacer clic en un "Submit" botón, evitar que enviar un formulario. 
        if (this.state.tipoModal === 'insertar') {
            this.peticionPost();
        }
    }

    peticionPost = async () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token')
            }
        }
        if (!expiredToken()) {
            await this.setState({ validacionS: this.validacionSelect() });
            this.state.usuario.Nombre = quitoEspacios(this.state.usuario.Nombre);// Quita los espacios en blanco al inicio, al final, y los que esten demas entre palabras
            this.state.usuario.Nombre = primeraMayuscula(this.state.usuario.Nombre); // Coloca la primer letra de cada palabra en mayuscula
            if (this.state.validacionS === false) { // Si no selecciona el rol muestro el mensaje de error y no entro al post
                if (this.state.usuario.Nombre !== '') {// verifico que el campo usuario.Nombre no sea vacio               
                    delete this.state.usuario.IdUsuario; //Borro el Id ya que la bd lo creo automaticamente 
                    await axios.post('Usuario', this.state.usuario, config) // Inserta en la Base de datos el nuevo registro
                        .then(response => {
                            this.props.listaUsuarios(); // obtengo la lista de roles pasada por props desde MantenimientoRol.js dentro de <ABrol>
                            this.onClose(); // Cierra el modal luego del ingreso  
                            mostrarAlertaExito(); //Muestra la alerta de verificacion luego de ingresado correctamente. Es creada y exportarda desde Mensajes.js e importada en CrearRol.js
                        }).catch(error => {
                            if (error.response) {
                                mostrarAlertaError(error.response.data.errors[0].detail); //aca traigo el error que coloque en la api
                                this.setState({ error: true }); // cambio el estado de error a true para que se muestre en el pie de drawer
                            }
                        }).catch(errorServer => {
                            errorServer = mostrarErrorCatch();
                        })
                }
            }
        }
        else {
            cerrarSesion();
        }
    }

    render() {

        return (
            <div>
                <Button type="primary" size='small'
                    onClick={() => {
                        this.showDrawer();
                        this.setState({ tipoModal: 'insertar' });
                        this.resertFormCrear();
                        this.setState({ error: false });
                    }}
                >
                    <PlusOutlined /> Nuevo Usuario
                </Button>

                <Drawer
                    title="Usuario - Nuevo"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    bodyStyle={{
                        paddingBottom: 30
                    }}
                    maskClosable={false}
                    footer={[
                        <p>
                            {mostrarMensajeFooter(this.state.error, 'Usuario')}
                        </p>
                    ]}
                >
                    <Form {...formItemLayout} onSubmitCapture={this.handleSubmit}>
                        <Form.Item
                            label="Numero">
                            <Input
                                disabled
                                name="IdUsuario"
                                type="text"
                                id="IdUsuario"
                                size="small"
                                value={this.state.usuario && this.state.usuario.IdUsuario}
                            >
                            </Input>
                        </Form.Item>
                        <Form.Item
                            label="Nombre"
                            required>
                            <Input
                                name="Nombre"
                                type="text"
                                id="Nombre"
                                minLength={5}
                                maxLength={50}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.usuario && this.state.usuario.Nombre}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacion, 'El usuario solo debe contener letras')}
                        </Form.Item>
                        <Form.Item
                            label="Contraseña"
                            required>
                            <Input
                                name="Contrasenia"
                                type="password"
                                id="Contrasenia"
                                minLength={5}
                                maxLength={16}
                                size="small"
                                onChange={this.handleChange}
                                value={this.state.usuario && this.state.usuario.Contrasenia}
                                required
                            >
                            </Input>
                            {mostrarMensajeValidacionFooter(this.state.validacionP, 'La contraseña solo debe contener letras, numeros y guiones')}
                        </Form.Item>
                        <Form.Item
                            label="Rol"
                            required
                        >
                            <Select
                                name="idRol"
                                id="idRol"
                                showSearch
                                size="small"
                                style={{ width: 200 }}
                                optionFilterProp="children"
                                onChange={(selectedGroup) => {
                                    this.setState({
                                        usuario: {
                                            ...this.state.usuario,
                                            idRol: selectedGroup
                                        },
                                        validacionS: false
                                    })
                                }} //el onChange del select no es como el input, por eso hago esta funcion flecha                              
                                placeholder="Buscar para seleccionar"
                                value={(this.state.usuario && this.state.usuario.idRol) || "0"}
                            >
                                <Option value="0"> <label style={{ color: '#bfbfbf', opacity: 1 }}> Seleccionar rol </label></Option>
                                {this.state.rol.map(elemento => (
                                    <Option key={elemento.idRol} value={elemento.idRol}>{elemento.tipoRol}</Option>
                                ))}
                            </Select>

                            {mostrarMensajeValidacionFooter(this.state.validacionS, 'Debe seleccionar un rol')}
                        </Form.Item>
                        <br></br>
                        <Form.Item wrapperCol={{ ...formItemLayout, offset: 8 }}>
                            <Space>
                                <Button
                                    type='button'
                                    value='Cancelar'
                                    onClick={this.onClose}>
                                    Cancelar
                                </Button>
                                {!this.state.validacion ?
                                    <Button
                                        type='primary'
                                        htmlType='submit'>
                                        Insertar
                                    </Button>
                                    :
                                    <Button
                                        type='primary'
                                        htmlType='submit'
                                        disabled='true'>
                                        Insertar
                                    </Button>
                                }
                            </Space>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div >
        )
    }
}