import React, { Component } from 'react'
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { Menu } from 'antd'; //npm i antd (instalar)
import {
    PieChartOutlined,
    SettingOutlined,
    TeamOutlined,
    CameraOutlined,
    FolderOpenOutlined,
    CreditCardOutlined,
    ShoppingCartOutlined,
    UsergroupDeleteOutlined,
    FileTextOutlined,
    ReadOutlined,
    SnippetsOutlined
} from '@ant-design/icons'; //npm i antd (instalar)

const { SubMenu } = Menu;

export default class MenuUsuario extends Component {

    render() {
        return (
            <Menu
                theme={this.props.theme}
                defaultSelectedKeys={['1']}
                mode="inline"
            >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <Link to="/Inicio">
                        Resumen
                    </Link>
                </Menu.Item>
                <SubMenu key="sub1" icon={<ReadOutlined />} title="Mis Trabajos">
                    <Menu.Item key="2" icon={<FolderOpenOutlined />}>
                        <Link to='/ListadoPortfolios'>
                            Portfolios
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<CameraOutlined />}>
                        <Link to='/ListadoFotografias'>
                            Fotografias
                        </Link>
                    </Menu.Item>
                </SubMenu>

                <SubMenu key="sub2" icon={<SettingOutlined />} title="Administración">
                    <Menu.Item key="4" icon={<TeamOutlined />}>
                        <Link to='/ListadoUsuarios'>
                            Usuarios
                        </Link>
                    </Menu.Item>                    
                    <Menu.Item key="5" icon={<FileTextOutlined />}>
                        <Link to='/ListadoCondicionPagos'>
                            Condicion Pago
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="6" icon={<FileTextOutlined />}>
                        <Link to='/ListadoCategorias'>
                            Categorias
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" icon={<ShoppingCartOutlined />} title="Ventas">
                    <Menu.Item key="7" icon={<UsergroupDeleteOutlined />}>
                        <Link to='/ListadoClientes'>
                            Clientes
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="8" icon={<SnippetsOutlined />}>
                        <Link to='/ListadoAltas'>
                            Altas
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="9" icon={<SnippetsOutlined />}>
                        <Link to='/ListadoTrabajos'>
                            Trabajos
                        </Link>
                    </Menu.Item>
                    <SubMenu key="sub4" icon={<FileTextOutlined />} title="Facturacion">
                        <Menu.Item key="10" icon={<FileTextOutlined />}>
                            <Link to='/ListadoFacturas'>
                                Todas
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="11" icon={<FileTextOutlined />}>
                            <Link to='/ListadoFacturasVencidas'>
                                Vencidas
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                    <Menu.Item key="12" icon={<CreditCardOutlined />}>
                    <Link to='/ListadoPagos'>
                            Pagos
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        )
    }
}