import React, { Component } from 'react';
import { Table, Form } from 'antd'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import BusquedaFacturas from '../Facturas/BusquedaFacturas';
import { MinusCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import ModificarFactura from './ModificarFactura';
import BajaFactura from './BajaFactura';
import DetalleFactura from './DetalleFactura';
import CrearPago from '../Pagos/CrearPago';
import VerPdf from './VerPdf';
import ListadoFacturasVencidas from './ListadoFacturasVencidas';

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

export default class ListadoFacturas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [],
            paginado: [],
            pagina: 0, // usado para cargar el numero de pagina del paginado en el handleChange el cual invoco al final en el onchange del table            
            validacion: false,
            validacionNumero: false,
            numeroFactura: '', // Usado para la busqueda
            nombreCliente: '' // Usado para la busqueda
        }

    };

    handleChange = async value => { //Obtengo el numero de pagina dentro del paginado
        await this.setState({
            pagina: value
        });
        this.peticionGet();
    }

    pasarUrl = () => { //Esta funcion es para no escribir .then mas de una vez dentro de peticion get
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (this.state.numeroFactura !== '' && this.state.nombreCliente === '') {
            return axios.get('Factura?numero=' + this.state.numeroFactura + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroFactura === '' && this.state.nombreCliente !== '') {
            return axios.get('Factura?nombreCliente=' + this.state.nombreCliente + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroFactura !== '' && this.state.nombreCliente !== '') {
            return axios.get('Factura?numero=' + this.state.numeroFactura + '&nombreCliente=' + this.state.nombreCliente + '&pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
        if (this.state.numeroFactura === '' && this.state.nombreCliente === '') {
            return axios.get('Factura?pagina=' + this.state.pagina, config) // Obtengo los datos de la base de datos La ruta de la api la configure en index .js y el config de Autorizacion.js, 
        }
    }

    peticionGet = async (numero = '', nombre = '') => { //los inicializo porque sino me dice undefined
        if (numero !== '' || nombre !== '') {
            await this.setState({
                numeroFactura: numero,
                nombreCliente: nombre,
                pagina: 0 //Aca si no esta en la pagina 0 lo obligo a que este para poder buscar
            });
        } else {
            await this.setState({
                numeroFactura: '',
                nombreCliente: ''
            });
        }
        if (!expiredToken()) {
            this.pasarUrl()
                .then(response => {
                    let facturas = response.data.data                    
                    facturas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                        let date = moment(new Date(el.fecha))
                        return el.fecha = date.format("DD-MM-YYYY");
                    })
                    facturas.map(bi => {
                        let base = bi.baseImponible + bi.imponible21Porciento;
                        return bi.baseImponible = base
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
    }

    render() {
        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idFactura',
                key: 'idFactura',
                width: '7%',
                sorter: (a, b) => a.idFactura - b.idFactura,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nº',
                dataIndex: 'numero',
                key: 'numero',
                width: '7%',
                sorter: (a, b) => a.numero - b.numero,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjTrabajo', 'unObjCliente', 'nombre'],
                key: 'nombre',
                width: '30%',
                sorter: (a, b) => a.unObjTrabajo.unObjCliente.nombre.localeCompare(b.unObjTrabajo.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '10%',
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Total €',
                dataIndex: 'total',
                key: 'total',
                width: '10%',
                align: "left",
                sorter: (a, b) => a.total - b.total,
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila).toFixed(2),
            },
            {
                title: 'Base €',
                dataIndex: 'baseImponible',
                key: 'baseImponible',
                width: '10%',
                align: "left",
                sorter: (a, b) => a.baseImponible - b.baseImponible,
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila).toFixed(2),
            },
            {
                title: 'Condicion',
                dataIndex: ['unObjCondicionPago', 'descripcion'],
                key: 'descripcion',
                width: '10%',
                sorter: (a, b) => a.unObjCondicionPago.descripcion.localeCompare(b.unObjCondicionPago.descripcion),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Estado',
                dataIndex: ['unObjEstado', 'descripcion'],
                key: 'descripcion',
                width: '5%',                
                render: fila => (fila === 'Impago') ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : (fila === 'Pago Parcial') ? <MinusCircleTwoTone twoToneColor="#fadb14" /> : <CheckCircleTwoTone twoToneColor="#52c41a" alt="Factura Paga" />
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '30%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarFactura
                                    filaSeleccionada={fila}
                                    listaFacturas={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaFactura
                                    filaSeleccionada={fila}
                                    listaFacturas={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleFactura
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <VerPdf
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <CrearPago
                                    filaSeleccionada={fila}
                                    listaFacturas = {this.peticionGet}
                                    datos={this.state.data}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Facturas</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <CloseCircleTwoTone twoToneColor="#cf1322" /> Impaga {tab} <MinusCircleTwoTone twoToneColor="#d4b106" /> Pago parcial {tab} <CheckCircleTwoTone twoToneColor="#52c41a" /> Pagada
                </div>
                <div style={{ float: 'right' }}>
                    <Form layout="inline" style={{ width: 340 }}>
                        <Form.Item style={{ width: 340 }}>
                            <BusquedaFacturas
                                listaFacturas={this.peticionGet}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ total: this.state.paginado.totalRegistros, pageSize: this.state.paginado.registrosPorPagina, onChange: this.handleChange }}
                    columns={columns.filter(col => col.dataIndex !== 'idFactura' /*&& col.dataIndex !== 'numero'*/)} //Ocluto 1 o varias columnas
                    dataSource={this.state.data}
                    rowKey={row => row.idFactura} />
            </div>
        );
    }
}