import React, { Component } from 'react'
import { Link } from 'react-router-dom'; //npm install react-router-dom (instalar)
import { Menu } from 'antd'; //npm i antd (instalar)
import {
    PieChartOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    FileTextOutlined,
    SnippetsOutlined
} from '@ant-design/icons'; //npm i antd (instalar)

const { SubMenu } = Menu;

export default class MenuUsuario extends Component {

    render() {
        return (
            <Menu
                theme={this.props.theme}
                defaultSelectedKeys={['1']}
                mode="inline"
            >
                <Menu.Item key="1" icon={<PieChartOutlined />}>
                    <Link to="/Inicio">
                        Resumen
                    </Link>
                </Menu.Item>

                <SubMenu key="sub2" icon={<SettingOutlined />} title="Administración">
                    <Menu.Item key="1" icon={<FileTextOutlined />}>
                        <Link to='/ListadoCategorias'>
                            Categorias
                        </Link>
                    </Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" icon={<ShoppingCartOutlined />} title="Ventas">
                    <Menu.Item key="2" icon={<SnippetsOutlined />}>
                        <Link to='/ListadoAltas'>
                            Altas
                        </Link>
                    </Menu.Item>
                </SubMenu>
            </Menu>
        )
    }
}