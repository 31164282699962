import React from 'react';
import ReactDOM from 'react-dom';
import Rutas from '../src/Paginas/Rutas'
import axios from 'axios';

//axios.defaults.baseURL = 'https://localhost/api/';
//axios.defaults.baseURL = 'https://192.168.1.143/api/';
axios.defaults.baseURL = 'https://api.diegogomezgarra.com/api/'; //Conexion al servidor web

ReactDOM.render(  
    <Rutas />,
  document.getElementById('root')
);