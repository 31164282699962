import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)

export default class DetalleFactura extends Component {

    openNotification = () => {
        const args = {
            description:
                <Descriptions size="default" column={1} title="Factura - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.numero} </Descriptions.Item>
                    <Descriptions.Item label="Cliente" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjTrabajo.unObjCliente.nombre}</Descriptions.Item>
                    <Descriptions.Item label="Detalle" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjTrabajo.nombre}</Descriptions.Item>
                    <Descriptions.Item label="Fecha" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.fecha}</Descriptions.Item>
                    <Descriptions.Item label="Base imponible" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.baseImponible - this.props.filaSeleccionada.imponible21Porciento).toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Iva" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.iva).toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Retenciones" labelStyle={{ fontWeight: 600 }}> <label style={{ color: '#cf1322' }}> -{(this.props.filaSeleccionada.retenciones).toFixed(2)}</label></Descriptions.Item>

                    {this.props.filaSeleccionada.gastos4Porciento !== '' ?
                        <Descriptions.Item label="Gastos 4% Iva" span={2} labelStyle={{ fontWeight: 600 }}>
                            {this.props.filaSeleccionada.gastos4Porciento} = € {(this.props.filaSeleccionada.imponible4Porciento).toFixed(2)}
                        </Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.gastos10Porciento !== '' ?
                        <Descriptions.Item label="Gastos 10% Iva" span={2} labelStyle={{ fontWeight: 600 }}>
                            {this.props.filaSeleccionada.gastos10Porciento} = € {(this.props.filaSeleccionada.imponible10Porciento).toFixed(2)}
                        </Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.gastos21Porciento !== '' ?
                        <Descriptions.Item label="Gastos 21% Iva" span={2} labelStyle={{ fontWeight: 600 }}>
                            {this.props.filaSeleccionada.gastos21Porciento} = € {(this.props.filaSeleccionada.imponible21Porciento).toFixed(2)}
                        </Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.gastosSinIva !== '' ?
                        <Descriptions.Item label="Gastos sin Iva" span={2} labelStyle={{ fontWeight: 600 }}>
                            {this.props.filaSeleccionada.gastosSinIva} = € {(this.props.filaSeleccionada.imponibleSinIva).toFixed(2)}
                        </Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.iva4Porciento !== '' ?
                        <Descriptions.Item label="Iva 4%" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.iva4Porciento).toFixed(2)}</Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.iva10Porciento !== '' ?
                        <Descriptions.Item label="Iva 10%" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.iva10Porciento).toFixed(2)}</Descriptions.Item>
                        :
                        ""
                    }

                    {this.props.filaSeleccionada.iva21Porciento !== '' ?
                        <Descriptions.Item label="Iva 21%" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.iva21Porciento).toFixed(2)}</Descriptions.Item>
                        :
                        ""
                    }
                    
                    <Descriptions.Item label="Total" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.total).toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Condicion de Pago" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjCondicionPago.descripcion}</Descriptions.Item>
                    <Descriptions.Item label="Estado de Pago" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjEstado.descripcion}</Descriptions.Item>

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {

        return (
            <div style={{ float: "left" }}>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style={{ fontSize: '15px' }}
                    onClick={() => this.openNotification()} />
            </div>
        )
    }
}