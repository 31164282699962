import React, { Component } from 'react';
import { Table } from 'antd'; //npm i antd (instalar)
import 'antd/dist/antd.css'; //npm i antd (instalar)
import axios from 'axios'; //npm install axios (instalar)
import moment from 'moment'// npm i moment (instalar para poder formatear las fechas)
import { mostrarErrorCatch } from '../../Paginas/Mensajes';
import { expiredToken, cerrarSesion } from '../../Paginas/Autorizacion';
import { MinusCircleTwoTone, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import ModificarFactura from './ModificarFactura';
import BajaFactura from './BajaFactura';
import DetalleFactura from './DetalleFactura';
import CrearPago from '../Pagos/CrearPago';
import VerPdf from './VerPdf';

const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;


export default class ListadoFacturasVencidas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            searchedColumn: '',
            data: [],
        }
    };

    peticionGet = () => { //los inicializo porque sino me dice undefined
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token'),
            }
        }
        if (!expiredToken()) {
            axios.get('Factura/Vencidas', config)
                .then(response => {
                    let facturas = response.data.data
                    facturas.map(el => { // aca mapeo la fecha para que se muestre en el formato comun de fecha, modifico la fecha en response.data.data para luego cargarla en el data del state.                        
                        let date = moment(new Date(el.fecha))
                        return el.fecha = date.format("DD-MM-YYYY");
                    })
                    this.setState({
                        data: response.data.data,
                        paginado: response.data.paginado,
                    }); // Cargo en data los resultados de la consulta                            
                }).catch(error => {
                    error = mostrarErrorCatch();
                });
        }
        else {
            cerrarSesion();
        }
    }

    componentDidMount() {
        this.peticionGet();
    }

    render() {
        var fechadia = new Date()

        const columns = [
            {
                title: 'Numero',
                dataIndex: 'idFactura',
                key: 'idFactura',
                width: '7%',
                sorter: (a, b) => a.idFactura - b.idFactura,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Nº',
                dataIndex: 'numero',
                key: 'numero',
                width: '7%',
                sorter: (a, b) => a.numero - b.numero,
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Cliente',
                dataIndex: ['unObjTrabajo', 'unObjCliente', 'nombre'],
                key: 'nombre',
                width: '30%',
                sorter: (a, b) => a.unObjTrabajo.unObjCliente.nombre.localeCompare(b.unObjTrabajo.unObjCliente.nombre),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Fecha',
                dataIndex: 'fecha',
                key: 'fecha',
                width: '10%',
                sorter: (a, b) => a.fecha.localeCompare(b.fecha),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Total €',
                dataIndex: 'total',
                key: 'total',
                width: '10%',
                align: "left",
                sorter: (a, b) => a.total - b.total,
                sortDirections: ['descend', 'ascend'],
                render: fila => (fila).toFixed(2),
            },
            {
                title: 'Condicion',
                dataIndex: ['unObjCondicionPago', 'descripcion'],
                key: 'descripcion',
                width: '10%',
                sorter: (a, b) => a.unObjCondicionPago.descripcion.localeCompare(b.unObjCondicionPago.descripcion),
                sortDirections: ['descend', 'ascend'],
            },
            {
                title: 'Estado',
                dataIndex: ['unObjEstado', 'descripcion'],
                key: 'descripcion',
                width: '5%',
                render: fila => (fila === 'Impago') ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : (fila === 'Pago Parcial') ? <MinusCircleTwoTone twoToneColor="#fadb14" /> : <CheckCircleTwoTone twoToneColor="#52c41a" alt="Factura Paga" />
            },
            {
                title: 'Días Vencida',
                key: 'descripcion',
                width: '7%',
                render: fila => (moment(fechadia.getTime()).diff(moment(fila.fecha, "DD-MM-YYYY").add(90, 'days'), 'days')) == 0 ?
                    <label style={{ color: 'red' }}> Hoy</label> 
                    :
                    (moment(fechadia.getTime()).diff(moment(fila.fecha, "DD-MM-YYYY").add(90, 'days'), 'days')) == 1 ?
                        <label style={{ color: 'red' }}>{(moment(fechadia.getTime()).diff(moment(fila.fecha, "DD-MM-YYYY").add(90, 'days'), 'days'))} Día</label>
                        :
                        <label style={{ color: 'red' }}>{(moment(fechadia.getTime()).diff(moment(fila.fecha, "DD-MM-YYYY").add(90, 'days'), 'days'))} Días</label>
                // Calcula los dias vencida la factura desde la fecha de la misma + los 90 dias de credito
            },
            {
                title: 'Acciones',
                key: 'acciones',
                width: '20%',
                render: fila => (
                    <>
                        <div>
                            <label>
                                <ModificarFactura
                                    filaSeleccionada={fila}
                                    listaFacturas={this.peticionGet}
                                    datos={this.state.data} />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <BajaFactura
                                    filaSeleccionada={fila}
                                    listaFacturas={this.peticionGet}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <DetalleFactura
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <VerPdf
                                    filaSeleccionada={fila}
                                />
                            </label>
                            <div style={{ float: "left" }}>
                                &nbsp;&nbsp; {/* Genero espacios en blanco*/}
                            </div>
                            <label>
                                <CrearPago
                                    filaSeleccionada={fila}
                                    listaFacturas={this.peticionGet}
                                    datos={this.state.data}
                                />
                            </label>
                        </div>
                    </>
                )
            },
        ];

        return (
            <div className='contenedor'>
                <div style={{ textAlign: 'center' }}>
                    <h3>Listado de Facturas Vencidas</h3>
                </div>
                <br />
                <div style={{ float: 'left' }}>
                    <CloseCircleTwoTone twoToneColor="#cf1322" /> Impaga {tab} <MinusCircleTwoTone twoToneColor="#d4b106" /> Pago parcial {tab}
                </div>
                <br /><br /><br /><br />
                <Table
                    showSorterTooltip={{ title: 'Clic para ordenar' }}
                    className="table, th, td"
                    size="small"
                    pagination={{ pageSize: 50 }}
                    columns={columns.filter(col => col.dataIndex !== 'idFactura' /*&& col.dataIndex !== 'numero'*/)} //Ocluto 1 o varias columnas
                    dataSource={this.state.data}
                    rowKey={row => row.idFactura} />
            </div>
        );
    }
}