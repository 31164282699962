import React, { Component } from 'react'
import '../../Estilos/estilos.css';
import { Descriptions, notification, Checkbox } from 'antd'; //npm i antd (instalar)
import { InfoCircleTwoTone } from '@ant-design/icons'; //npm i antd (instalar)
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

export default class DetalleAlta extends Component {
 
    openNotification = () => {
        const args = {
            description:
                <Descriptions size="small" column={1} title="Alta - Ficha" contentStyle={{ color: "slategray" }}>

                    <Descriptions.Item label="Número" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.idAlta} </Descriptions.Item>
                    <Descriptions.Item label="Empresa" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.empresa}</Descriptions.Item>                    
                    <Descriptions.Item label="Fecha Inicio" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.fechaInicio}</Descriptions.Item>
                    <Descriptions.Item label="Fecha Vencimiento" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.fechaVencimiento}</Descriptions.Item> 
                    <Descriptions.Item label="Finalizado" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.completado === false ? <CloseCircleTwoTone twoToneColor="#531dab" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}</Descriptions.Item> 
                    <Descriptions.Item label="Importe" labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.importe).toFixed(2)}</Descriptions.Item> 
                    <Descriptions.Item label="Importe Sin Imp." labelStyle={{ fontWeight: 600 }}> {(this.props.filaSeleccionada.importeSinImpuestos).toFixed(2)}</Descriptions.Item> 
                    <Descriptions.Item label="Dìas" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.dias}</Descriptions.Item> 
                    <Descriptions.Item label="Cobrado" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.cobrado === false ? <CloseCircleTwoTone twoToneColor="#cf1322" /> : <CheckCircleTwoTone twoToneColor="#52c41a" />}</Descriptions.Item>          
                    <Descriptions.Item label="Proyecto" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.proyecto}</Descriptions.Item>                     
                    <Descriptions.Item label="Categoria" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjCategoria.descripcion}</Descriptions.Item>
                    <Descriptions.Item label="Usuario" labelStyle={{ fontWeight: 600 }}> {this.props.filaSeleccionada.unObjUsuario.nombre}</Descriptions.Item>                    

                </Descriptions>,
            duration: 0,
        };
        return (
            notification.open(args)
        );
    }

    render() {        

        return (
            <div style={{ float: "left" }}>
                <InfoCircleTwoTone
                    twoToneColor="#7cb305"
                    style = {{fontSize:'15px'}}                    
                    onClick={() => this.openNotification()} />  
            </div>
        )
    }
}